<script setup lang="ts">
  import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router'
  import { useToast } from 'primevue/usetoast'
  import { onMounted, onUnmounted, ref, toRefs, watch } from 'vue'
  import { storeToRefs } from 'pinia'
  import { useBreakpointsStore, useProjectStore, useUserStore } from '@/store'
  import ProjectMedia from '@/views/projects/project-media/ProjectMedia.vue'
  import ProjectNotes from '@/views/projects/notes/ProjectNotes.vue'
  import ProjectsRelated from '@/views/projects/projects_related/ProjectsRelated.vue'
  import api from '@/services/api'
  import { PhotoTypeEnum } from '@/db/tables/PhotoQueue.table'
  import ProjectPunchList from '@/views/projects/punch_list/ProjectPunchList.vue'
  import ProjectAssignCrews from '@/views/projects/assign_crews/ProjectAssignCrews.vue'
  import ProjectWorkContacts from '@/views/projects/ProjectWorkContacts.vue'
  import ProjectProblems from './problems/ProjectProblems.vue'
  import ProjectViewLaborBill from './labor_bill/ViewLaborBill.vue'
  import ProjectDocuments from './ProjectDocuments.vue'
  import { i18n } from '@/i18n'
  import ContactSheet from '@/components/ContactSheet.vue'
  import { fetchCurrentLocationOnce } from '@/services/geolocation'
  import PermissionRole from '@/components/PermissionRole/PermissionComponent.vue'
  import { getPermission, getPermissionsUser } from '@/components/PermissionRole/funtions'
  import TabView from 'primevue/tabview'
  import { Capacitor } from '@capacitor/core'
  import GalleryComponent from '@/components/GalleryComponent.vue'
  import { useEventBus } from '@vueuse/core'
  import { ICamera } from '@/components/PunchListCamera/Camera/CameraContainer.vue'
  import { addNewIQueuePhotosCamera, deleteNewIQueuePhoto, IQueuePhoto, newIQueuePhoto, QueueStatusType, updateNewIQueuePhoto } from '@/db/tables'
  import { TPhotoContent } from '@/constants/types'
  import { IProject } from '@/interfaces/project/project.interface'
  import ProjectHeaderInfo from './ProjectHeaderInfo.vue'
  import { base64ToBlob } from '@/utils/base64Toblob'
  import uploadNewQueuePhoto from '@/composables/uploadQueuePhoto'
  import QueueCardProjectLead from '@/components/QueueCardProjectLead.vue'
  import devicePhotoTag from '@/utils/device-photo-tag'
  import { captureException } from '@sentry/vue'
  import { delay } from '@/utils/delay'
  import DialogCheckinProject from '@/components/DialogCheckinProject.vue'
  import { checkinUpdate } from '@/services/project'
  import { AxiosError } from 'axios'

  type IProjectCheckIn = IProject & { isLoading: boolean }
  type ErrorProject = { responseException: { exceptionMessage: { message: string } } }
  type ErrorCloseoutProject = { response: { data: ErrorProject } }

  const router = useRouter()
  const getTabsPermission = (
    menu: {
      id: string
      label: string
      keyNodo: string
      keyName: string
    }[]
  ) => {
    return menu.filter((item) => getPermission(getPermissionsUser(), item.keyName, item.keyNodo))
  }
  const { t } = i18n.global
  const userStore = useUserStore()
  const breakpointsStore = useBreakpointsStore()
  const { md } = storeToRefs(breakpointsStore)

  const { zohoIntegration, permissions } = storeToRefs(userStore)
  const loadingProjects = ref(true)
  const isLoadingProjectStatus = ref(false)
  const route = useRoute()
  const projectId = ref(`${route.params.id}`)

  const tab = ref(`${route.params.tab}`)

  onBeforeRouteUpdate((to, from, next) => {
    // if (to?.params?.tab === from?.params?.tab) return
    tab.value = `${to?.params?.tab}`
    next()
  })
  const photoNotification = ref<null | TPhotoContent>(null)
  const contactInfoVisible = ref(false)
  const typePhotoVisible = ref(false)
  const multifacetedCheckInVisible = ref(false)
  const projectsCheckIn = ref<IProjectCheckIn[]>([])
  const isUploading = ref(false)
  const showDialogCloseOut = ref(false)
  const queueStore = uploadNewQueuePhoto()
  const { uploadQueuePhotos } = queueStore
  const { queue } = toRefs(queueStore)
  const { fetchProjectById, setProjectError, setLoadingProjects, destroy, setProjectMedia } = useProjectStore()
  const projectStore = useProjectStore()
  const { project, projectMedia, loadingProjectMedia } = storeToRefs(projectStore)

  const typePhtos = [
    { value: PhotoTypeEnum.PREPRODUCTION_PICTURES, label: t('photoType.PREPRODUCTION_PICTURES') },
    { value: PhotoTypeEnum.PROGRESS_PICTURES, label: t('photoType.PROGRESS_PICTURES') },
  ]

  const editImage = async (value: { id: string; base64: string; base64Content: string }) => {
    if (!value) return
    setProjectMedia(
      projectMedia.value.map((x) => {
        if (x.id === value.id) return { ...x, url: value.base64 }
        return x
      })
    )
    await api.patch(`/Api/Project/${project.value.id}/Photo/${value.id}`, {
      base64Content: value.base64Content,
    })
  }

  async function populateProjectDetails() {
    try {
      setLoadingProjects(true)
      await fetchProjectById(projectId.value)
    } catch (error: any) {
      captureException(error)
      const responseError = error.response?.data as ErrorProject
      const { responseException } = responseError
      const {
        exceptionMessage: { message },
      } = responseException

      const messagesExceptions: string[] = ['User is not authorized to see this project', 'Project not found']

      setProjectError(error)
      toast.add({ severity: 'error', summary: t('appLayout.titleunsuccess'), detail: `${message}`, life: 3000 })
      await delay(2000)
      if (messagesExceptions?.includes(`${message}`)) {
        await router.push('/projects')
      }
    } finally {
      setLoadingProjects(false)
      loadingProjects.value = false
    }
  }

  const tabs = [
    { id: 'media', label: t('projectId.media'), keyNodo: 'projectsMediaPermissions', keyName: 'show' },
    { id: 'documents', label: t('projectId.documents'), keyNodo: 'documentsPermissions', keyName: 'show' },
    { id: 'punch-list', label: t('projectId.punchList'), keyNodo: 'projectsPunchListsPermissions', keyName: 'show' },
    { id: 'assigned-crew', label: t('projectId.crews'), keyNodo: 'projectsCrewsPermissions', keyName: 'show' },
    { id: 'work-contacts', label: t('projectId.workContacts'), keyNodo: 'workContactsPermissions', keyName: 'show' },
    { id: 'notes', label: t('projectId.notes'), keyNodo: 'projectsNotePermissions', keyName: 'show' },
    { id: 'problems', label: t('projectId.problems'), keyNodo: 'projectsProblemsPermissions', keyName: 'show' },
    { id: 'labor-bill', label: t('projectId.laborBill'), keyNodo: 'projectsLaborBillPermissions', keyName: 'show' },
  ]

  const tabMenu = ref(getTabsPermission(tabs))
  const tabActive = ref(tabMenu.value.findIndex((thisTab) => thisTab.id.includes(tab.value)) || 0)

  watch(tab, (currTab) => {
    tabActive.value = tabMenu.value.findIndex((thisTab) => thisTab.id.includes(currTab)) || 0
  })
  const toast = useToast()

  const handleCheckinProject = async () => {
    if (project.value?.isMultifaceted) {
      multifacetedCheckInVisible.value = true
      const response = await api.get(`/Api/Projects/${projectId.value}/GetByReference`)
      projectsCheckIn.value = [
        ...response.data.data,
        {
          ...project.value,
          name: project.value.projectName,
          isLoading: false,
        },
      ]
    } else {
      await checkinProject(projectId.value)
    }
  }

  const showDialogRelocation = ref(false)
  const loadinCheckin = ref(false)
  const loadingUpdateCheckin = ref(false)

  const handleUpdateCheckin = async () => {
    try {
      loadingUpdateCheckin.value = true
      const { latitude, longitude } = await fetchCurrentLocationOnce(true)
      await checkinUpdate({
        id: projectId.value,
        payload: {
          latitude,
          longitude,
        },
        type: 'Projects',
      })
      showDialogRelocation.value = false
      toast.add({ severity: 'success', summary: t('appLayout.titlesuccess'), detail: t('projectId.checkInUpdate.success'), life: 3000 })
    } catch (error) {
      toast.add({
        severity: 'error',
        summary: t('appLayout.titleunsuccess'),
        detail: t('projectId.checkInUpdate.error'),
        life: 3000,
      })
    } finally {
      loadingUpdateCheckin.value = false
    }
  }
  const checkinProject = async (projectId: string) => {
    try {
      loadinCheckin.value = true
      const { latitude, longitude } = await fetchCurrentLocationOnce(true)
      await api.post('/Api/Projects/CheckIn', {
        projectId,
        latitude: `${latitude}`,
        longitude: `${longitude}`,
      })
      toast.add({ severity: 'success', summary: t('appLayout.titlesuccess'), detail: t('projectId.checkedInCorrectly'), life: 3000 })
    } catch (error: any) {
      const errorMessage = error.response?.data?.responseException?.exceptionMessage?.message

      toast.add({
        severity: 'error',
        summary: t('appLayout.titleunsuccess'),
        detail: errorMessage ?? t('projectId.errorCheckingIn'),
        life: 3000,
      })

      const label = 'You are not in the project area'
      if (errorMessage === label) {
        showDialogRelocation.value = true
      }
    } finally {
      loadinCheckin.value = false
    }
  }

  const loadingClosing = ref(false)
  const handleCloseOutProject = async () => {
    try {
      loadingClosing.value = true

      await api.patch(`/Api/Project/${projectId.value}/CloseOut`, {
        isClosed: true,
      })
      await populateProjectDetails()
      showDialogCloseOut.value = false
      toast.add({ severity: 'success', summary: t('appLayout.titlesuccess'), detail: t('projectId.closeout.successDescription'), life: 3000 })
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.add({
          severity: 'error',
          summary: t('appLayout.titleunsuccess'),
          detail: error.response?.data?.responseException?.exceptionMessage?.message ?? t('projectId.closeout.errorDescription'),
          life: 3000,
        })
      }
    } finally {
      loadingClosing.value = false
    }
  }

  const isLoadingCloseout = ref(false)
  async function handleCloseProject() {
    try {
      isLoadingCloseout.value = true
      const response = await api.get(`/Api/Project/${projectId.value}/CanItBeClosed`)
      const data = await response.data
      const successMessage = data?.data?.message?.includes('Ready to close')

      if (data?.status === 200 && successMessage) {
        showDialogCloseOut.value = true
      }
    } catch (err: unknown) {
      const error = err as ErrorCloseoutProject
      const messageException = error?.response?.data?.responseException?.exceptionMessage?.message
      toast.add({ severity: 'error', summary: t('appLayout.titleunsuccess'), detail: `${messageException}`, life: 3000 })
    } finally {
      isLoadingProjectStatus.value = false
      isLoadingCloseout.value = false
    }
  }

  const requestPhotoNotification = async () => {
    if (route.query?.photo) {
      photoNotification.value = null
      const { data } = await api.get(`/Api/Project/Photo/${route.query?.photo}`)
      // console.log('photo', data.data)
      photoNotification.value = data.data
    }
  }

  watch(project, () => {
    if (project?.value?.isMultifaceted && tabs.some((tab) => tab.id === 'projects-related') === false) {
      tabs.push({
        id: 'projects-related',
        label: t('projectId.projectsRelated'),
        keyNodo: 'projectsLaborBillPermissions',
        keyName: 'show',
      })
      tabMenu.value = getTabsPermission(tabs)
    }
  })

  const openUrlInTab = (address: string, latitude?: number, longitude?: number) => {
    let url = ''

    if (Capacitor.getPlatform() === 'ios') {
      if (latitude !== undefined && longitude !== undefined && latitude !== null && longitude !== null) {
        url = `http://maps.apple.com/?q=${latitude},${longitude}`
      } else {
        url = `http://maps.apple.com/?q=${address}`
      }
    } else {
      if (latitude !== undefined && longitude !== undefined && latitude !== null && longitude !== null) {
        url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
      } else {
        url = `https://www.google.com/maps/search/?api=1&query=${address}`
      }
    }

    const _url = new URL(url)
    // console.log(_url.href)
    window.open(_url.href, '_blank', 'noreferrer')
  }

  function tabClick(tabEvent: { index: number; originalEvent: Event }) {
    router.push(`/projects/${projectId.value}/${tabMenu.value[tabEvent.index].id}`)
  }

  const redirectTab = (tab: string) => {
    const tabMenuIndex = tabMenu.value.findIndex((item) => item.id === tab)
    tabActive.value = tabMenuIndex
    router.push(`/projects/${projectId.value}/${tab}`)
  }

  function openContactSheet() {
    contactInfoVisible.value = !contactInfoVisible.value
  }

  watch(
    () => `${route.params.id}`,
    async (newProjectId, oldProjectId) => {
      if (newProjectId !== oldProjectId && oldProjectId !== undefined && newProjectId !== `undefined`) {
        loadingProjects.value = true
        projectId.value = newProjectId
        await populateProjectDetails()
      }
    },
    {
      immediate: true,
    }
  )

  watch(
    () => `${route.query?.photo}-${route.query?.date}`,
    async (newphoto, oldPhoto) => {
      if (newphoto !== oldPhoto) await requestPhotoNotification()
    }
  )

  const camera = useEventBus<ICamera>('camera')

  const closeCamera = async () => {
    await uploadQueuePhotos()
    // await delay(1000)
  }

  const uploadPhotos = async (photos: IQueuePhoto[]) => {
    const uploadPhotoType = await devicePhotoTag('UPLOAD')
    const info = photos
      .filter((media) => media.type_media === 'IMAGE')
      .map((photo) => {
        const blob = base64ToBlob(`${photo.base64}`, 'image/jpeg')
        const photoData: newIQueuePhoto = {
          id: photo.id,
          projectId: photo.projectId,
          entityName: project.value.projectName,
          poNumberProject: project.value?.poNumber ?? '',
          latitude: photo.latitude,
          longitude: photo.longitude,
          entityType: 'Project',
          photoType: photo.photoType,
          photoTypeId: photo.photoTypeId,
          file: blob,
          status: 'IDLE' as QueueStatusType,
          date: new Date(),
          errorMessage: '',
          retries: 0,
          uploadPhotoType: uploadPhotoType,
        }
        return { ...photoData }
      })

    await addNewIQueuePhotosCamera(info)
    await uploadQueuePhotos()
    await delay(1000)
  }

  const takePicture = async (photo: IQueuePhoto) => {
    const blob = base64ToBlob(`${photo.base64}`, 'image/jpeg')
    const uploadPhotoType = await devicePhotoTag('CAMERA')

    const photoData: newIQueuePhoto = {
      id: photo.id,
      entityName: project.value.projectName,
      poNumberProject: project.value?.poNumber ?? '',
      projectId: photo.projectId,
      latitude: photo.latitude,
      longitude: photo.longitude,
      photoType: photo.photoType,
      entityType: 'Project',
      photoTypeId: photo.photoTypeId,
      file: blob,
      status: 'IDLE' as QueueStatusType,
      date: new Date(),
      errorMessage: '',
      retries: 0,
      uploadPhotoType: uploadPhotoType,
    }
    await addNewIQueuePhotosCamera([photoData])
  }

  const deletePhoto = async (photo: IQueuePhoto) => {
    await deleteNewIQueuePhoto(photo.id)
  }

  const editPhoto = async (photo: IQueuePhoto) => {
    const uploadPhotoType = await devicePhotoTag('CAMERA')
    const blob = base64ToBlob(`${photo.base64}`, 'image/jpeg')
    const photoData: newIQueuePhoto = {
      id: photo.id,
      poNumberProject: project.value?.poNumber ?? '',
      projectId: photo.projectId,
      entityType: 'Project',
      latitude: photo.latitude,
      longitude: photo.longitude,
      photoType: photo.photoType,
      photoTypeId: photo.photoTypeId,
      file: blob,
      status: 'IDLE' as QueueStatusType,
      date: new Date(),
      errorMessage: '',
      retries: 0,
      uploadPhotoType,
    }

    await updateNewIQueuePhoto(photoData)
  }

  const initCamera = (id: string, typePhoto: PhotoTypeEnum) => {
    camera.emit({
      propsCamera: {
        projectId: id,
        photoType: typePhoto,
      },
      actions: {
        takePicture: takePicture,
        closeCamera: closeCamera,
        deletePhoto: deletePhoto,
        editPhoto: editPhoto,
      },
    })
  }

  onMounted(async () => {
    await populateProjectDetails()
    await requestPhotoNotification()
    uploadQueuePhotos()
  })
  onUnmounted(() => {
    destroy()
  })
</script>

<template>
  <Toast position="bottom-right" />

  <Dialog
    v-model:visible="showDialogCloseOut"
    :pt="{
      mask: {
        style: {
          zIndex: 1215,
        },
      },
    }"
    :modal="true"
    :base-z-index="1215"
    :auto-z-index="false"
    :header="t('projectId.closeout.title')"
    class="w-full m-0"
    style="max-width: 42rem; z-index: 1215"
    :draggable="false"
  >
    <div class="flex w-full align-items-center gap-4">
      <div class="w-full flex flex-column p-input-icon-left w-full relative p-input-icon-right gap-4">
        <p>
          {{ t('projectId.closeout.description') }}
        </p>
        <div class="flex flex-row w-full justify-content-end gap-2">
          <Button :label="t('projectId.closeout.buttons.cancel')" severity="primary" :outlined="true" class="border-0 shadow-none" raised @click="showDialogCloseOut = false" />
          <Button :label="t('projectId.closeout.buttons.confirm')" severity="danger" raised :loading="loadingClosing" @click="handleCloseOutProject" />
        </div>
      </div>
    </div>
  </Dialog>
  <template v-if="!loadingProjects">
    <GalleryComponent
      v-if="photoNotification !== null"
      :images="[photoNotification]"
      :loading-next-images="loadingProjectMedia"
      show-detail
      active-comment
      :show-comments="project.isAuthorized"
      :class-custom="{
        container: 'hidden',
      }"
      entity-type="Projects"
      @edit-image="(data: any | undefined) => editImage(data.image)"
    />

    <div class="grid mx-0 justify-content-center relative h-full">
      <div class="col col-12 px-0 flex flex-column" style="height: 100%">
        <div class="grid">
          <div class="col col-12">
            <ProjectHeaderInfo :project="project" :go-back="() => router.push('/projects')" :is-uploading="isUploading" />

            <div class="flex-row flex justify-content-evenly align-items-center gap-2 w-full" :class="{ 'h-5rem': !md, 'mb-2': !md }">
              <!-- customer details -->
              <PermissionRole :nodo-name="'buttons'" :key-name="'contact'">
                <Button class="btn flex-column md:flex-row flex justify-content-center align-items-center w-full" :class="{ 'h-full': !md, 'px-2': !md }" @click="openContactSheet">
                  <svg style="height: 24px; width: 24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <title>account</title>
                    <path fill="currentColor" d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z" />
                  </svg>
                  <span class="text-xs mt-1 md:text-base md:mt-0 md:ml-2 font-bold">{{ t('projectId.contact') }}</span>
                </Button>
              </PermissionRole>

              <!-- directions -->
              <PermissionRole :nodo-name="'buttons'" :key-name="'directions'">
                <Button
                  class="btn flex-column md:flex-row flex justify-content-center align-items-center w-full"
                  :class="{ 'h-full': !md, 'px-2': !md }"
                  @click="openUrlInTab(`${project.address}`, project.latitude, project.longitude)"
                >
                  <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M14,14.5V12H10V15H8V11A1,1 0 0,1 9,10H14V7.5L17.5,11M21.71,11.29L12.71,2.29H12.7C12.31,1.9 11.68,1.9 11.29,2.29L2.29,11.29C1.9,11.68 1.9,12.32 2.29,12.71L11.29,21.71C11.68,22.09 12.31,22.1 12.71,21.71L21.71,12.71C22.1,12.32 22.1,11.68 21.71,11.29Z"
                    />
                  </svg>
                  <span class="text-xs mt-1 md:text-base md:mt-0 md:ml-2 font-bold">{{ t('projectId.directions') }}</span>
                </Button>
              </PermissionRole>

              <!-- check-in -->
              <PermissionRole v-if="project.isAuthorized" :nodo-name="'buttons'" :key-name="'checkIn'">
                <Button
                  class="btn flex-column md:flex-row flex justify-content-center align-items-center w-full"
                  :class="{ 'h-full': !md, 'px-2': !md }"
                  :disabled="getPermission(permissions, 'buttons', 'zoho') ? !zohoIntegration : false"
                  :loading="loadinCheckin"
                  @click="handleCheckinProject"
                >
                  <svg v-if="!loadinCheckin" style="width: 24px; height: 24px" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z"
                    />
                  </svg>
                  <svg v-if="loadinCheckin" class="rotating" style="width: 24px; height: 24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                  </svg>
                  <span class="text-xs mt-1 md:text-base md:mt-0 md:ml-2 font-bold">{{ $t('projectId.checkIn') }}</span>
                </Button>
              </PermissionRole>

              <!-- project close-out -->
              <PermissionRole v-if="project.isAuthorized && !project.isClosed" :nodo-name="'buttons'" :key-name="'closeOut'">
                <!-- :disabled="isCloseOutDisabled" -->
                <Button
                  class="btn flex-column md:flex-row flex justify-content-center align-items-center w-full"
                  :class="{ 'h-full': !md, 'px-2': !md }"
                  :loading="isLoadingCloseout"
                  @click="handleCloseProject"
                >
                  <svg v-if="!isLoadingCloseout" style="width: 24px; height: 24px" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M0.41,13.41L6,19L7.41,17.58L1.83,12M22.24,5.58L11.66,16.17L7.5,12L6.07,13.41L11.66,19L23.66,7M18,7L16.59,5.58L10.24,11.93L11.66,13.34L18,7Z"
                    />
                  </svg>
                  <i v-else class="pi pi-spin pi-spinner" style="font-size: 1.2rem; color: white"></i>
                  <span class="text-xs mt-1 md:text-base md:mt-0 md:ml-2 font-bold">{{ $t('projectId.closeProject') }}</span>
                </Button>
              </PermissionRole>
            </div>
          </div>
        </div>

        <div class="grid">
          <div class="col overflow-hidden">
            <TabView
              v-model:active-index="tabActive"
              :scrollable="true"
              :previous-button-props="{
                style: 'display: none',
              }"
              :next-button-props="{
                style: 'display: none',
              }"
              @tab-click="tabClick"
            >
              <TabPanel
                v-for="item in tabMenu.filter((item) => getPermission(getPermissionsUser(), item.keyName, item.keyNodo))"
                :key="item.id"
                :header="item.label"
                content-class="hidden"
              />
            </TabView>
          </div>
        </div>
        <div class="grid overflow-y-auto overflow-x-hidden flex-1 layout-save-m">
          <div
            v-if="(queue ?? []).length > 0"
            class="grid align-content-start w-full h-10rem p-2 flex-column mb-3 align-items-srart gap-2"
            :class="(queue ?? []).length > 3 && 'custombar overflow-x-scroll'"
          >
            <QueueCardProjectLead v-for="photo in queue || []" :key="photo.id" :photo="photo" />
          </div>

          <div v-if="tabMenu[tabActive]?.id === 'media'" class="col-12 h-full relative px-0">
            <ProjectMedia
              :project-id="projectId"
              @uploading="
                (e) => {
                  uploadPhotos(e)
                }
              "
            />

            <!-- @uploading="
              (e) => {
                uploadPhotos(e)
              }
            " -->
            <div class="h-2rem" />
          </div>

          <div v-if="tabMenu[tabActive]?.id === 'documents'" class="col-12 h-full h-full relative px-0">
            <ProjectDocuments :project-data="project" />

            <div class="h-2rem" />
          </div>

          <div v-if="tabMenu[tabActive]?.id === 'punch-list'" class="col-12 h-full h-full relative px-0">
            <ProjectPunchList :project-id="projectId" />

            <div class="h-2rem" />
          </div>

          <div v-if="tabMenu[tabActive]?.id === 'assigned-crew'" class="col-12 h-full h-full relative px-0">
            <ProjectAssignCrews :project-id="projectId" />

            <div class="h-2rem" />
          </div>

          <div v-if="tabMenu[tabActive]?.id === 'work-contacts'" class="col-12 h-full h-full relative px-0">
            <ProjectWorkContacts :project-id="projectId" />

            <div class="h-2rem" />
          </div>

          <div v-if="tabMenu[tabActive]?.id === 'notes'" class="col-12 h-full h-full relative px-0">
            <ProjectNotes :project-id="projectId" />

            <div class="h-6rem" />
          </div>

          <div v-if="tabMenu[tabActive]?.id === 'problems'" class="col-12 h-full h-full relative px-0">
            <ProjectProblems :project-id="projectId" />

            <div class="h-6rem" />
          </div>

          <div v-if="tabMenu[tabActive]?.id === 'labor-bill'" class="col-12 h-full h-full relative px-0">
            <ProjectViewLaborBill :project-id="projectId" />

            <div class="h-6rem" />
          </div>
          <div v-if="tabMenu[tabActive]?.id === 'projects-related'" class="col-12 h-full h-full relative px-0">
            <ProjectsRelated :project-id="projectId" />
            <div class="h-6rem" />
          </div>
        </div>
        <PermissionRole v-if="Capacitor.isNativePlatform() && tabMenu[tabActive]?.id !== 'labor-bill'" :nodo-name="'quickMenu'" :key-name="'show'">
          <div
            class="z-5 flex justify-content-center py-4 layout-save relative"
            style="background-color: #f9fafb; width: 100%; position: fixed !important; bottom: 0; right: 0; left: 0; z-index: 1 !important; border-top: 3px solid #e5e7eb"
          >
            <div class="absolute flex gap-2">
              <PermissionRole :nodo-name="'quickMenu'" :key-name="'documents'">
                <Button rounded icon="pi pi-folder" class="p-4 shadow-3 btn-camera" severity="secondary" style="top: -40px" @click="() => redirectTab('documents')" />
              </PermissionRole>
              <!-- <PermissionRole :nodo-name="'quickMenu'" :key-name="'camera'">
                <Button rounded icon="pi pi-camera" class="p-5 shadow-3 btn-camera" style="top: -50px" @click="() => takePictures(projectId)" />
              </PermissionRole> -->
              <PermissionRole :nodo-name="'quickMenu'" :key-name="'notes'">
                <Button rounded icon="pi pi-pencil" class="p-4 shadow-3 btn-camera" severity="secondary" style="top: -40px" @click="() => redirectTab('notes')" />
              </PermissionRole>
            </div>
          </div>
        </PermissionRole>
      </div>
    </div>

    <Dialog
      v-model:visible="contactInfoVisible"
      :position="!md ? 'bottom' : undefined"
      class="w-full m-0"
      style="max-width: 36rem"
      :modal="true"
      :dismissable-mask="true"
      :draggable="false"
      :close-on-escape="true"
      :closable="true"
    >
      <template #header>
        <div class="flex flex-row w-full align-items-center gap-2">
          <Avatar
            :image="project.contact?.photo ?? `https://contacts.zoho.com/file?ID=${project.contact?.zuid ?? ''}&fs=thumb`"
            size="large"
            shape="circle"
            style="background-color: rgba(0, 0, 0, 0.1)"
          />
          <p class="font-bold text-xl m-0 text-overflow-ellipsis white-space-nowrap">{{ project.contact?.fullName ?? project.contact?.firstName }}</p>
        </div>
      </template>
      <ContactSheet :project-contact="project.contact" />
      <div
        :style="{
          marginBottom: !md ? 'calc(var(--sab) + 4rem) !important' : '',
        }"
      ></div>
    </Dialog>
    <Dialog
      v-model:visible="showDialogRelocation"
      class="w-full ml-8"
      style="max-width: 40rem; z-index: 1215"
      modal
      :draggable="false"
      close-on-escape
      closable
      :header="t('projectId.checkInUpdate.title')"
    >
      <Message severity="error" :closable="false" icon="pi pi-lock">{{ t('projectId.checkInUpdate.projectError') }} </Message>
      <p>
        {{ t('projectId.checkInUpdate.makeSure') }}
      </p>
      <Button
        class="btn flex-row flex justify-content-center align-items-center w-full bg-black-alpha-90 border-0"
        :label="t('projectId.checkInUpdate.finish')"
        :loading="loadingUpdateCheckin"
        @click="handleUpdateCheckin"
      >
      </Button>
    </Dialog>
    <Dialog
      v-model:visible="typePhotoVisible"
      :modal="true"
      :auto-z-index="true"
      :header="t('projectId.selectTypePhoto')"
      class="w-full m-0"
      style="max-width: 42rem"
      :draggable="false"
      :position="!md ? 'bottom' : undefined"
      :dismissable-mask="true"
      :close-on-escape="true"
      :closable="true"
    >
      <Dropdown
        :options="typePhtos"
        option-label="label"
        :placeholder="t('projectId.selectTypePhotoPlaceholder')"
        class="w-full"
        :style="{
          marginBottom: !md ? 'calc(var(--sab) + 4rem) !important' : '',
        }"
        @change="
          (e) => {
            typePhotoVisible = false
            initCamera(projectId, e.value.value)
          }
        "
      />
    </Dialog>

    <Dialog
      v-model:visible="multifacetedCheckInVisible"
      :position="!md ? 'bottom' : undefined"
      class="w-full ml-8"
      style="max-width: 40rem; z-index: 1215"
      modal
      :draggable="false"
      close-on-escape
      closable
      :header="`${t('projectId.checkIn')} ${t('projectId.projectsRelated')} `?.toUpperCase()"
    >
      <div class="flex w-full flex-column gap-3 border-round-xs">
        <div v-for="item in projectsCheckIn" :key="item.id" class="flex flex-column justify-content-between align-items-center w-full gap-2">
          <section class="w-full flex flex-column gap-1">
            <p class="text-left font-bold mb-0 text-overflow-ellipsis white-space-nowrap overflow-hidden">
              {{ item?.name }}
            </p>
            <div class="flex flex-column flex-wrap">
              <p class="text-600 text-left text-md mb-0 text-overflow-ellipsis white-space-nowrap overflow-hidden">
                {{ item.poNumber }}
              </p>
              <p class="text-600 text-left text-md mb-0 text-overflow-ellipsis white-space-nowrap overflow-hidden">
                {{ item.projectStage }}
              </p>
              <p class="text-600 text-left text-md mb-0 text-overflow-ellipsis white-space-nowrap overflow-hidden">
                {{ item.projectType }}
              </p>
            </div>
          </section>
          <DialogCheckinProject :project-id="item.id" />
        </div>
      </div>
      <div
        :style="{
          marginBottom: !md ? 'calc(var(--sab) + 4rem) !important' : '',
        }"
      ></div>
    </Dialog>
  </template>

  <template v-else>
    <div class="card">
      <div>
        <div>
          <div>
            <Skeleton width="60%" />
          </div>
          <div class="mt-1 text-600">
            <Skeleton width="40%" />
          </div>
        </div>
      </div>
      <hr />
      <div class="grid">
        <div class="col">
          <Skeleton width="100%" height="8rem" />
        </div>
      </div>
      <hr />
      <div class="grid align-items-center justify-content-between">
        <div class="col">
          <Skeleton />
        </div>
        <div class="col">
          <div>
            <Skeleton />
          </div>
        </div>
        <div class="col">
          <div class="text-right">
            <Skeleton />
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<style lang="scss" scoped>
  .title-tab {
    min-height: 40px;
  }
  .masonry-item-container {
    height: 200px;
  }
  .masonry-item {
    background-color: #eee;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    height: 100%;

    figure {
      height: 100%;
      width: 100%;
      border-radius: 5px;
      margin: 0;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: relative;
        border-radius: 5px;
      }

      figcaption {
        width: 100%;
        bottom: 0;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.65);
        color: #ffffff;
        display: flex;
        flex-direction: row;
        padding: 0.5rem;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        img {
          height: 42px;
          width: 42px;
        }

        .subtext {
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }
  }
  .custombar {
    scrollbar-width: thin;
    scrollbar-color: #3b82f6 #b9bdc1;
  }
  .custombar::-webkit-scrollbar {
    width: 5px;
    height: 6px;
  }

  .custombar::-webkit-scrollbar-track {
    background: #b9bdc1;
  }

  .custombar::-webkit-scrollbar-thumb {
    background: #3b82f6;
    border-radius: 50px;
  }

  .custombar::-webkit-scrollbar-thumb:hover {
    background: #2563eb;
  }
</style>
<style lang="css" scoped>
  .layout-save-m {
    margin-bottom: calc(var(--sab) + 1rem) !important;
  }
  .layout-save {
    padding-bottom: calc(var(--sab) + 1rem) !important;
  }

  .grid {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0rem;
    margin-left: 0rem;
    margin-top: 0rem;
  }
  .ProseMirror {
    border: none;
    outline: none;
  }
  .ProseMirror:focus {
    border: none;
    outline: none;
  }
  .ProseMirror-focus {
    border: none;
    outline: none;
  }
  .gallery-item {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
  .review-buttons {
    display: grid;
    gap: 1rem;
  }
  .p-rating .p-rating-icon.pi-star-fill {
    color: orange !important;
  }
  .p-rating .p-rating-icon {
    font-size: 1.7rem !important;
  }
  .p-tabview {
    margin-bottom: 0;
  }
</style>
<style>
  button.btn-camera .p-button-icon.pi.pi-camera {
    font-size: 1.5rem;
  }
</style>
