<script lang="ts" setup>
  import { onMounted, Ref, ref, toRefs } from 'vue'
  import api from '@/services/api'
  import { useToast } from 'primevue/usetoast'
  import { ICrew } from '@/interfaces/crew/Crew'
  import { IAssignedCrews } from '@/store'
  import { captureException } from '@sentry/vue'
  import { i18n } from '@/i18n'
  function filtrarDatos<T>(datos: T[], filtro: T[], key: keyof T) {
    return datos.filter((dato) => filtro.every((f) => f[key] !== dato[key]))
  }
  const emit = defineEmits(['updateAssignedCrews'])

  interface IProps {
    assignedCrews?: IAssignedCrews[]
    projectId: string
  }

  const props = defineProps<IProps>()
  const { assignedCrews } = toRefs(props)
  const toast = useToast()
  const { t } = i18n.global
  const assignedCrewsFieldValue: Ref<ICrew[]> = ref([])
  const assignedCrewsSuggestion: Ref<ICrew[]> = ref([])
  const assignedCrewsProps: Ref<ICrew[]> = ref([])
  const isSubmitting = ref(false)

  const assignCrew = async () => {
    try {
      if (!assignedCrewsFieldValue.value?.length) {
        toast.add({ severity: 'error', summary: t('appLayout.titleunsuccess'), detail: 'Error assigning crew', life: 3000 })
        isSubmitting.value = false
        return
      }

      isSubmitting.value = true
      const payload = { id: assignedCrewsFieldValue.value.map((c) => c.id) }

      const {
        data: { data: resp },
      } = await api.post(`/Api/Project/${props.projectId}/AssociateCrewCompanies`, payload)

      toast.add({ severity: 'success', summary: t('appLayout.titlesuccess'), detail: 'Crew successfully assigned', life: 3000 })

      emit('updateAssignedCrews', resp)
      isSubmitting.value = false
    } catch (err: any) {
      captureException(new Error(err))
      isSubmitting.value = false
      toast.add({
        severity: 'error',
        summary: t('appLayout.titleunsuccess'),
        detail: err.response.data.responseException.exceptionMessage.message || 'Error assigning crew',
        life: 3000,
      })
    }
  }

  const searchCrewToAssignToProject = async ($event: any) => {
    const term = $event.query
    assignedCrewsSuggestion.value = await searchCrewsToAssign(term)
  }

  const resetAssignCrewModel = () => {
    assignedCrewsFieldValue.value = []
    assignedCrewsSuggestion.value = []
  }

  const searchCrewsToAssign = async (searchTerm: string): Promise<any[]> => {
    const {
      data: { data: resp },
    } = await api.get(`/Api/Projects/CrewCompanies?query=${searchTerm}`)
    return resp
  }

  onMounted(async () => {
    resetAssignCrewModel()
    assignedCrewsProps.value = (assignedCrews?.value ?? []).map((c) => {
      return { ...c, id: c.crewCompanyId } as unknown as ICrew
    })
  })
</script>

<template>
  <div class="flex flex-column gap-3">
    <div class="w-full flex flex-column gap-1">
      <label for="assignToUser">{{ $t('projectAssignCrewUsers.selectCrew') }}</label>
      <AutoComplete
        v-model="assignedCrewsFieldValue"
        class="w-full"
        :dropdown="true"
        :force-selection="true"
        option-label="name"
        multiple
        :placeholder="$t('projectAssignCrewUsers.assignCrew')"
        :suggestions="filtrarDatos(assignedCrewsSuggestion, [...assignedCrewsFieldValue, ...assignedCrewsProps], 'id')"
        :pt="{
          container: {
            style: {
              width: '100%',
            },
          },
        }"
        @complete="searchCrewToAssignToProject($event)"
      >
        <template #item="slotProps">
          <div class="flex justify-content-start align-items-center">
            <div>
              <img v-if="slotProps.item?.logoUrl" :src="slotProps.item?.logoUrl" height="50" />
              <img v-else src="/assets/default/crews.png" height="50" />
            </div>
            <div>
              <div class="ml-2 text-base w">{{ slotProps.item?.name }}</div>
              <div v-if="slotProps.item?.manager" class="ml-2 text-base w">
                {{ slotProps.item?.manager?.fullName }}
              </div>
            </div>
          </div>
        </template>
      </AutoComplete>
    </div>
    <div class="flex justify-content-end">
      <Button :label="t('projectProblems.create')" class="w-full" :disabled="isSubmitting" :loading="isSubmitting" @click="assignCrew()" />
    </div>
  </div>
</template>

<style lang="css" scoped></style>
