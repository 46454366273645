export const objectToParams = (obj: Record<string, any>) => {
  const params = new URLSearchParams()
  for (const key in obj) {
    if (obj[key] != null) {
      params.append(key, String(obj[key]))
    }
  }

  return params.toString()
}
