<script lang="ts" setup>
  import { ref, toRefs } from 'vue'
  import api from '@/services/api'
  import { useToast } from 'primevue/usetoast'
  import { i18n } from '@/i18n'

  type Props = {
    id: string
    name: string
  }
  const props = defineProps<Props>()
  const { id: crewId, name } = toRefs(props)
  const toast = useToast()
  const loading = ref(false)
  const showDialogConfirmation = ref(false)
  const { t } = i18n.global
  const handleSendInvitation = async () => {
    try {
      loading.value = true
      await api.get(`/Api/InvitationCrew/${crewId.value}`)
      showDialogConfirmation.value = false
      toast.add({
        severity: 'success',
        summary: t('appLayout.titlesuccess'),
        detail: `${t('crewDirectory.sentInvitationCrew.first')} ${name?.value ?? ''} ${t('crewDirectory.sentInvitationCrew.second')}`,
        life: 3000,
      })
    } catch (error) {
      toast.add({
        severity: 'error',
        summary: t('appLayout.titleunsuccess'),
        detail: t('crewDirectory.errorSendInvitationCrew'),
        life: 3000,
      })
    } finally {
      loading.value = false
    }
  }
</script>
<template>
  <Toast position="bottom-right" />
  <Dialog v-model:visible="showDialogConfirmation" :header="t('crewDirectory.sendInvitation')" class="w-full m-0" style="max-width: 42rem" :modal="true" :draggable="false">
    <section class="flex flex-column gap-2">
      <p>
        {{ t('crewDirectory.confirmationDescription') }}
      </p>
      <div class="flex flex-row gap-2">
        <Button
          :label="t('galleryComponent.dialog.removePhotoCancel')"
          class="p-button-text"
          @click="showDialogConfirmation = false"
          @touchstart="showDialogConfirmation = false"
        />
        <Button
          :label="t('galleryComponent.dialog.removePhotoConfirm')"
          :loading="loading"
          class="p-button-danger"
          @click="handleSendInvitation"
          @touchstart="handleSendInvitation"
        />
      </div>
    </section>
  </Dialog>
  <Button class="w-fit bg-black-alpha-90 border-black-alpha-90" :label="t('crewDirectory.sendInvitation')" @click="showDialogConfirmation = true" />
</template>
