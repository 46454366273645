<script lang="ts" setup>
  import { onMounted, ref } from 'vue'
  import api from '@/services/api'
  import Card from 'primevue/card'
  import { i18n } from '@/i18n'
  import { useRoute } from 'vue-router'
  import { useUserStore } from '@/store/Account/UserStore'
  import { useToast } from 'primevue/usetoast'
  import LoaderComponent from '@/components/LoaderComponent.vue'
  import Button from 'primevue/button'
  import { connectToZoho } from '@/utils/connectToZoho'
  import { captureException } from '@sentry/vue'

  const toast = useToast()
  const userStore = useUserStore()
  const { getUserInfo } = userStore
  const { t } = i18n.global
  const route = useRoute()
  const integration = `${route.params.integration}`

  const loading = ref(true)
  const success = ref(false)
  const failed = ref(false)
  const integrationConnecting = ref(false)

  async function retryIntegration() {
    try {
      switch (integration) {
        case 'zoho':
          await initiateZohoConnection()
          break
        default:
          break
      }
    } catch (err) {
      captureException(err)
      console.log('err', err)
    }
  }

  async function initiateZohoConnection() {
    try {
      integrationConnecting.value = true

      await connectToZoho()

      integrationConnecting.value = false
    } catch (err) {
      captureException(err)
      console.log('err', err)

      integrationConnecting.value = false
    }
  }

  async function fetchIntegration() {
    try {
      loading.value = true
      switch (integration) {
        case 'zoho':
          await saveZohoIntegrations()
          break
        default:
          break
      }
    } catch (err) {
      captureException(err)
      console.log('err', err)
    }
  }

  async function saveZohoIntegrations() {
    try {
      const code = route.query.code
      console.log('code', code)

      const { status } = await api.get(`/Api/ZohoSaveToken?code=${code}`)

      console.log('response status', status)

      if (status === 200) {
        await getUserInfo()
        success.value = true
        failed.value = false
        loading.value = false
      } else {
        toast.add({
          severity: 'error',
          summary: t('appLayout.titleunsuccess'),
          detail: 'Error connecting to Zoho, try again later',
          life: 3000,
        })
        failed.value = true
        success.value = false
        loading.value = false
      }
    } catch (err) {
      captureException(err)
      console.log('err', err)
    }
  }

  onMounted(async () => {
    await fetchIntegration()
  })
</script>

<template>
  <div class="h-full w-full flex align-items-center justify-content-center">
    <Card id="integration-card" :class="{ failed: failed }">
      <template #title>
        <template v-if="loading">
          Connecting to
          {{ integration.charAt(0).toUpperCase() + integration.slice(1) }}
        </template>

        <template v-if="success">
          Connected to
          {{ integration.charAt(0).toUpperCase() + integration.slice(1) }}
        </template>

        <template v-if="failed">
          Failed to connect to
          {{ integration.charAt(0).toUpperCase() + integration.slice(1) }}
        </template>
      </template>
      <template #content>
        <template v-if="loading">
          <LoaderComponent style="position: relative; height: 200px; width: 200px" />
        </template>

        <template v-if="success">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="text-green-600" height="200px" width="200px">
            <title>check-circle</title>
            <path d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" />
          </svg>
        </template>

        <template v-if="failed">
          <div class="flex flex-column align-items-center justify-content-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="text-red-600" height="200px" width="200px">
              <title>alert-circle</title>
              <path d="M13,13H11V7H13M13,17H11V15H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
            </svg>
            <Button label="Try Again" :loading="integrationConnecting" :disabled="integrationConnecting" style="margin-top: 24px" @click="retryIntegration"></Button>
          </div>
        </template>
      </template>
    </Card>
  </div>
</template>

<style lang="scss">
  #integration-card {
    max-width: 100%;

    .p-card-title {
      text-align: center;
    }

    .p-card-content {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 48px 64px !important;
    }

    &.failed {
      .p-card-content {
        padding-bottom: 24px !important;
      }
    }
  }
</style>
