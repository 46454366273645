<script lang="ts" setup>
  import { ref } from 'vue'
  import api from '@/services/api'
  import Menu from 'primevue/menu'
  import { useToast } from 'primevue/usetoast'
  import { ICrewUser } from './Types'
  import { getPermission, getPermissionsUser } from '@/components/PermissionRole/funtions'
  import ReviewIndex from '@/components/PunchListCamera/Reviews/ReviewsEdit.vue'
  import PermissionRole from '@/components/PermissionRole/PermissionComponent.vue'
  import { captureException } from '@sentry/vue'
  import { useProjectStore } from '@/store'
  import { storeToRefs } from 'pinia'
  import { i18n } from '@/i18n'
  import { useRouter } from 'vue-router'

  // TODO: centralize these interfaces

  //  :class=" statesCrew[props.status].button
  // props.status === 'READY_FOR_PRODUCTION'
  //   ? 'p-button-secondary'
  //   : props.status === 'IN_PRODUCTION'
  //   ? 'p-button-info'
  //   : props.status === 'PENDING_REVIEW'
  //   ? 'p-button-warning'
  //   : props.status === 'PENDING_PUNCHLIST'
  //   ? 'p-button-warning'
  //   : 'p-button-success'
  // "
  const statesCrew = {
    READY_FOR_PRODUCTION: {
      button: 'p-button-secondary',
      text: 'Ready For Production',
    },
    IN_PRODUCTION: {
      button: 'p-button-info',
      text: 'In Production',
    },
    PENDING_REVIEW: {
      button: 'p-button-warning',
      text: 'Pending Review',
    },
    PENDING_PUNCHLIST: {
      text: 'Pending Punchlist',
      button: 'p-button-warning',
    },
    COMPLETED: {
      button: 'p-button-success',
      text: 'Completed',
    },
  }

  type StatusCrews = 'READY_FOR_PRODUCTION' | 'IN_PRODUCTION' | 'PENDING_REVIEW' | 'PENDING_PUNCHLIST' | 'COMPLETED'

  const slidesCrews = (status: StatusCrews) => {
    return statesCrew[(status as keyof typeof statesCrew) ?? 'COMPLETED']
  }

  interface ICrewManager {
    fullName: string
    email?: string
    phone: string
    rating: null
  }

  interface IAssignedCrews {
    id: string
    name: string
    manager?: ICrewManager
    reviews: any[]
    status: 'READY_FOR_PRODUCTION' | 'IN_PRODUCTION' | 'PENDING_REVIEW' | 'PENDING_PUNCHLIST' | 'COMPLETED'
    ratingCache: number
    ratingCount: number
    zuid: string
    editableReviews?: boolean
    crewCompanyId: string
  }
  const toast = useToast()
  const { t } = i18n.global
  const props = defineProps<IAssignedCrews>()
  const permissions = getPermissionsUser()
  const emit = defineEmits<{
    (e: 'removeUser', value: { crewUser: ICrewUser }): void
    (e: 'updateAssignedCrews'): void
    (e: 'assingCrewUsers', value: { crewCompanyId: string }): void
    (e: 'viewCrewUser', value: { crewCompanyId: string }): void
  }>()
  const projectStore = useProjectStore()
  const editReview = ref(false)
  const { project } = storeToRefs(projectStore)
  const menu = ref()
  const router = useRouter()

  const statuses = ref(
    getPermission(permissions, 'editCrewStatus', 'projectsCrewsPermissions')
      ? [
          {
            label: 'Pending Review',
            value: 'PENDING_REVIEW',
            command: () => updateCrewStatus('PENDING_REVIEW'),
          },
        ]
      : [
          {
            label: 'Ready For Production',
            value: 'READY_FOR_PRODUCTION',
            command: () => updateCrewStatus('READY_FOR_PRODUCTION'),
          },
          {
            label: 'In Production',
            value: 'IN_PRODUCTION',
            command: () => updateCrewStatus('IN_PRODUCTION'),
          },
          {
            label: 'Pending Review',
            value: 'PENDING_REVIEW',
            command: () => updateCrewStatus('PENDING_REVIEW'),
          },
          {
            label: 'Pending Punchlist',
            value: 'PENDING_PUNCHLIST',
            command: () => updateCrewStatus('PENDING_PUNCHLIST'),
          },
          {
            label: 'Completed',
            value: 'COMPLETED',

            command: () => updateCrewStatus('COMPLETED'),
          },
        ]
  )

  const assignCrewUser = () => {
    emit('assingCrewUsers', { crewCompanyId: props.crewCompanyId })
  }
  const viewCrewUser = () => {
    emit('viewCrewUser', { crewCompanyId: props.crewCompanyId })
  }
  const updateCrewStatus = async (status: string) => {
    try {
      const payload = {
        projectCrewCompanyId: props?.id,
        status,
      }
      await api.patch('/Api/Projects/ProjectCrewCompanies/Status', payload)

      toast.add({
        severity: 'success',
        summary: t('appLayout.titlesuccess'),
        detail: 'Successfully updated crew status',
        life: 3000,
      })

      emit('updateAssignedCrews')
    } catch (err) {
      captureException(err)
      console.log('err', err)
      toast.add({
        severity: 'error',
        summary: t('appLayout.titleunsuccess'),
        detail: 'Error updating crew status',
        life: 3000,
      })
    }
  }
  const toggleEditReview = () => {
    editReview.value = !editReview.value
  }
  function renderPhoneHref(phone: string) {
    return 'tel:+1' + phone.replace(/\D/g, '')
  }

  function renderSMSHref(phone: string) {
    return 'sms:+1' + phone.replace(/\D/g, '')
  }

  const toggleMenu = (event: MouseEvent) => {
    if (menu.value && menu.value.toggle) {
      menu.value.toggle(event)
    } else if (menu.value && menu.value[0] && menu.value[0].toggle) {
      menu.value[0].toggle(event)
    }
  }

  const saveReview = async () => {
    try {
      const reviewsRequest = props?.reviews.map((item) => {
        const review = { ...item, projectCrewCompanyId: props?.id ?? '' }
        return review
      })

      const responses = await Promise.all(reviewsRequest.map((review) => api.post('/Api/Projects/Reviews', review)))
      editReview.value = false
      console.log({ responses })
    } catch {
      console.log('no se guardó')
    }
  }
</script>

<template>
  <div class="flex w-full justify-content-start flex-wrap CardWithEffect">
    <div class="p-card w-full shadow-none p-4 flex-column">
      <div class="flex justify-content-between align-items-center flex-column gap-1">
        <div class="flex align-items-center flex-column gap-2 justify-content-center relative w-full">
          <header class="p-grid" style="width: 100%; display: grid; grid-template-columns: 1fr auto">
            <section class="p-col-6">
              <Avatar :image="`https://contacts.zoho.com/file?ID=${props.zuid}&fs=thumb`" size="large" shape="circle" class="h-9rem w-9rem" />
              <p class="text-2xl font-bold cursor-pointer" @click="router.push(`/my-company/${props.crewCompanyId}`)">
                {{ props.manager?.fullName }}
              </p>
            </section>
            <section class="align-items-start justify-content-end flex">
              <PermissionRole v-if="!project.isClosed && project.isAuthorized" :nodo-name="'projectsCrewsPermissions'" :key-name="'crewManagerRemove'">
                <Button
                  v-if="!project.isClosed"
                  style="background-color: transparent; border: none"
                  class="p-0"
                  @click="
                    () => {
                      emit('removeUser', {
                        crewUser: {
                          fullName: props.manager?.fullName,
                          email: props.manager?.email,
                          id: props.id,
                          marketLocations: [],
                        },
                      })
                    }
                  "
                >
                  <i class="pi pi-times text-2xl" style="color: red" />
                </Button>
              </PermissionRole>
            </section>
          </header>
          <div class="flex flex-column justify-content-between align-items-start gap-2" style="width: 100%">
            <div class="flex flex-row align-items-center justify-content-center gap-3">
              <a v-if="props.manager?.email" :href="`mailto:${props.manager?.email}`" class="p-button-rounded p-button-text text-lg w-40 h-40">
                <i class="pi pi-envelope text-2xl text-color-secondary" />
              </a>
              <a v-if="props.manager?.phone" :href="renderPhoneHref(props.manager?.phone)" class="p-button-rounded p-button-text text-lg w-40 h-40">
                <i class="pi pi-phone text-2xl text-color-secondary" />
              </a>
              <a v-if="props.manager?.phone" :href="renderSMSHref(props.manager?.phone)" class="w-40 h-40 p-button-rounded p-button-text text-lg">
                <i class="pi pi-comment text-2xl text-color-secondary" />
              </a>
            </div>

            <div class="flex flex-row flex-wrap gap-2">
              <Menu ref="menu" :model="statuses" :popup="true" style="font-size: 14px" />

              <Button data-value="COMPLETED" class="p-tag border-none p-2" :class="slidesCrews(props.status).button" :disabled="project.isClosed" @click="toggleMenu">
                <span class="flex-grow-1 pl-2 text-sm">
                  {{ slidesCrews(props.status).text }}
                </span>
                <i class="pi pi-angle-down text-md ml-2"></i>
              </Button>
              <PermissionRole v-if="!project.isClosed && project.isAuthorized" :nodo-name="'projectsCrewsPermissions'" :key-name="'assignCrewUserWithinCrewManager'">
                <Button class="p-tag border-none p-2" :label="t('projectAssignCrewUsers.assingCrewUser')" @click="assignCrewUser" />
              </PermissionRole>
              <PermissionRole v-if="!project.isClosed && project.isAuthorized" :nodo-name="'projectsCrewsPermissions'" :key-name="'viewCrewUserWithinCrewManager'">
                <Button class="p-tag border-none p-2" :label="t('projectAssignCrewUsers.viewCrewUser')" @click="viewCrewUser" />
              </PermissionRole>
            </div>
            <!-- <div
              class="text-lg font-bold cursor-pointer"
              @click="router.push(`/my-company/${props?.crewCompanyId}`)"
            >
              {{ props.name }}
            </div> -->
          </div>
        </div>
      </div>
      <p class="my-2 font-bold text-lg">{{ t('crewCardReview') }}</p>

      <ReviewIndex v-if="editReview" :crews-for-review="[props as any]"></ReviewIndex>
      <p v-if="!props.reviews?.length" class="p-0 m-0">{{ t('crewMessageReviews') }}</p>
      <div v-for="(review, index) of props?.reviews" v-else :key="index" class="flex flex-column gap-1">
        <header class="flex flex-row-reverse gap-1 pt-2">
          <label for="workmanship_comments" class="w-full font-bold">{{ t(review.reviewType === 'WORKMANSHIP' ? 'reviewsCrew.workmanship' : 'reviewsCrew.timeliness') }}</label>
          <p class="my-0">
            <Rating :model-value="review.rating" :stars="5" :cancel="false" />
          </p>
        </header>
        <p class="my-2 font-italic ReviewText">{{ review.comment }}</p>
      </div>
      <PermissionRole v-if="props?.editableReviews" nodo-name="projectsCrewsPermissions" key-name="reviewEditable">
        <div class="flex justify-content-center">
          <Button v-if="props.reviews?.length" class="border-none mx-2" :class="editReview ? 'p-button-danger' : 'p-button-info'" @click="toggleEditReview">
            <span class="flex-grow-1 text-md"> {{ !editReview ? t('reviewsCrew.edit') : t('reviewsCrew.cancel') }} </span>
          </Button>
          <Button v-if="editReview" class="p-button-info border-none mx-2" @click="saveReview">
            <span class="flex-grow-1 text-md"> {{ t('reviewsCrew.save') }} </span>
          </Button>
        </div>
      </PermissionRole>
    </div>
  </div>
</template>

<style>
  .ReviewText {
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
    line-height: 1.2em;
  }
  .CardWithEffect {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08);
  }
</style>
