<script lang="ts" setup>
  import { onMounted, ref } from 'vue'
  import { storeToRefs } from 'pinia'
  import api from '@/services/api'
  // import { useRoute } from 'vue-router'
  import { useToast } from 'primevue/usetoast'
  import { INote } from '@/models/note/interfaces'
  import Button from 'primevue/button'
  import NoteComponent from './NoteComponent.vue'
  import { i18n } from '@/i18n'
  import PermissionRole from '@/components/PermissionRole/PermissionComponent.vue'
  import { useBreakpointsStore, useProjectStore, useUserStore } from '@/store'
  import { captureException } from '@sentry/vue'
  import EditorContent from '@/components/EditorNotes/EditorContent.vue'
  import { EditorComponent } from '@/components/EditorNotes/editorInterface'
  import { computed } from 'vue'

  type Iprops = {
    projectId: string
  }
  interface IEditedNote {
    id: string
    Note_Title: string
    Note_Content: string
  }

  const props = defineProps<Iprops>()
  // const route = useRoute()
  const { t } = i18n.global

  const projectId = computed(() => {
    return props.projectId
  }).value

  const userStore = useUserStore()
  const { zohoIntegration, zuid, fullName } = storeToRefs(userStore)
  const projectStore = useProjectStore()
  const { fetchWorkContacts } = useProjectStore()
  const { projectWorkContacts, project } = storeToRefs(projectStore)
  const noteInfo = ref('')
  const editorChild = ref<EditorComponent | null>(null)
  const dialogIsActive = ref(false)
  const getWorkContacts = async () => {
    await fetchWorkContacts(props.projectId)
  }
  const breakpointsStore = useBreakpointsStore()
  const { md } = storeToRefs(breakpointsStore)
  const toast = useToast()

  // const activateDialog = () => {
  //   dialogIsActive.value = true
  // }

  const clearHtml = (html = '') => {
    const regex = /(<([^>]+)>)/gi
    return html.replace(regex, '')
  }

  const notes = ref<INote[]>([])
  const submitLoading = ref(false)
  const noteReply = ref<INote | null>(null)
  // const editor = ref()

  function updateNoteAfterEdit(editedNote: IEditedNote) {
    const note = notes.value.find((n) => n.id === editedNote.id)
    if (!note) return
    note.Note_Title = editedNote.Note_Title
    note.Note_Content = editedNote.Note_Content
  }

  function removeNoteAfterDelete(deletedNote: { id: string }) {
    const noteIndex = notes.value.findIndex((n) => n.id === deletedNote.id)
    if (noteIndex > -1) notes.value.splice(noteIndex, 1)
  }

  const updatecontent = (content: string) => {
    noteInfo.value = content
  }

  async function createNote() {
    if (clearHtml(noteInfo?.value)?.trim()?.length === 0) return
    try {
      submitLoading.value = true
      const html = noteInfo.value
      const payload = noteReply.value
        ? {
            Note_Content: html,
            NoteReply: {
              ReplyZuidId: noteReply.value.Owner.zuid,
              ReplyCRMId: noteReply.value.Owner.id,
              ReplyAuthor: noteReply.value.Owner.name,
              RePlyContent: noteReply.value.Note_Content,
            },
          }
        : {
            Note_Content: html,
          }

      await api.post(`/Api/Project/${projectId}/Notes`, { ...payload })
      noteReply.value = null
      noteInfo.value = ''
      submitLoading.value = false
      dialogIsActive.value = false
      toast.add({ severity: 'success', summary: t('appLayout.titlesuccess'), detail: i18n.global.t('projectNotes.noteAdded'), life: 3000 })
    } catch (err) {
      const error = err as TErrorFech
      toast.add({ severity: 'error', summary: t('appLayout.titleunsuccess'), detail: `${error?.response?.data?.responseException?.exceptionMessage?.message}`, life: 3000 })
      captureException(err)
      submitLoading.value = false
      // toast.add({ severity: 'error', summary: i18n.global.t('projectNotes.error'), detail: i18n.global.t('projectNotes.errorAddingNote'), life: 3000 })
    } finally {
      if (editorChild.value) {
        await fetchNotes()
        editorChild.value.commands.clearEditorContent()
      }
    }
  }

  type TErrorFech = {
    response: {
      data: {
        responseException: {
          exceptionMessage: {
            message: string
          }
        }
      }
    }
  }

  async function fetchNotes() {
    try {
      const response = await api.get(`/Api/Project/${projectId}/Notes`)

      notes.value = [...response.data.data.data]
    } catch (err) {
      const error = err as TErrorFech
      toast.add({ severity: 'error', summary: t('appLayout.titleunsuccess'), detail: `${error?.response?.data?.responseException?.exceptionMessage?.message}`, life: 3000 })
      captureException(err)
    }
  }

  onMounted(async () => {
    await fetchNotes()
    await getWorkContacts()
  })
</script>

<template>
  <div class="col-12">
    <div class="flex align-items-center justify-content-between title-tab">
      <div class="flex text-xl align-items-center">
        <div class="flex pr-2">
          <i class="pi pi-list" />
        </div>
        <div class="flex text-xl">{{ t('projectNotes.notes') }}</div>
      </div>
      <!-- <div class="flex">
        <Button
          v-if="project.isAuthorized && !project.isClosed && zohoIntegration"
          :label="t('projectNotes.addNotes')"
          icon="pi pi-plus"
          class="p-button-text"
          @click="activateDialog"
        />
      </div> -->
    </div>
    <div class="col-12">
      <div class="m-auto max-w-30rem gap-4 flex flex-column">
        <!-- isAuthorized:{{ project.isAuthorized }} --- isClosed:{{ project?.isClosed }} -- zohoIntegration: {{ zohoIntegration }} -->
        <Card class="w-full">
          <template #header>
            <div class="w-full flex flex-row p-2 max-w-100" style="border-bottom: 1px solid rgba(0, 0, 0, 0.15)">
              <div class="flex flex-row w-full text-overflow-ellipsis white-space-nowrap overflow-hidden" style="max-width: calc(100% - 42px)">
                <Avatar :image="'https://contacts.zoho.com/file?ID=' + zuid + '&fs=thumb'" size="large" shape="circle" />
                <div class="flex flex-column w-full ml-2" style="max-width: calc(100% - 46px)">
                  <p class="font-bold text-lg m-0 text-overflow-ellipsis white-space-nowrap overflow-hidden cursor-pointer block" style="color: #3b82f6">{{ fullName }}</p>
                  <p class="text-sm" style="color: rgba(0, 0, 0, 0.45)">{{ t('projectNotes.addNote') }}</p>
                </div>
              </div>
            </div>
          </template>
          <template #content>
            <div v-if="project.isAuthorized && !project.isClosed && zohoIntegration" class="w-full flex flex-row align-items-center gap-2">
              <EditorContent
                ref="editorChild"
                :items-suggestions="projectWorkContacts"
                class="flex-1"
                :placeholder="i18n.global.t('projectNotes.noteEditor')"
                @create:note="createNote"
                @update:note="updatecontent"
              />
              <Button icon="pi pi-send" :loading="submitLoading" :disabled="clearHtml(noteInfo).trim()?.length === 0 ? true : submitLoading" @click="createNote" />
            </div>
            <ul>
              <li v-if="project.isClosed" class="text-red-600">{{ t('noteComponent.projectIsClosed') }}</li>
              <li v-if="!project.isAuthorized" class="text-red-600">{{ t('noteComponent.isNotAuthorized') }}</li>
              <li v-if="!zohoIntegration" class="text-red-600">{{ t('noteComponent.zohoIsIntegrate') }}</li>
            </ul>
          </template>
        </Card>
        <div v-for="note in notes" :key="note.id" class="flex flex-row mb-3">
          <NoteComponent
            :note="note"
            @update-note="updateNoteAfterEdit"
            @delete-note="removeNoteAfterDelete"
            @reply-note="
              () => {
                dialogIsActive = true
                noteReply = note
              }
            "
          />
        </div>

        <Dialog
          v-model:visible="dialogIsActive"
          :position="!md ? 'bottom' : undefined"
          class="w-full m-0 hide-on-camera"
          style="max-width: 42rem"
          :modal="true"
          :draggable="false"
          @hide="
            () => {
              noteInfo = ''
              noteReply = null
            }
          "
        >
          <template #header>
            <h5>{{ t('projectNotes.addNote') }}</h5>
          </template>
          <PermissionRole :nodo-name="'projectsNotePermissions'" :key-name="'create'">
            <div v-if="zohoIntegration" class="w-full flex flex-column align-items-center gap-2 layout-save">
              <div v-if="noteReply" class="w-full flex flex-row gap-1 container-reply">
                <div class="w-full flex flex-column gap-1">
                  <p class="font-bold text-lg m-0 text-overflow-ellipsis white-space-nowrap overflow-hidden block" style="color: #3b82f6">
                    {{ noteReply?.Owner?.name }}
                  </p>
                  <p class="text-sm" style="color: #aeaeae">
                    {{ clearHtml(noteReply?.Note_Content) }}
                  </p>
                </div>
              </div>
              <div class="w-full flex flex-row align-items-center gap-2">
                <EditorContent
                  ref="editorChild"
                  :items-suggestions="projectWorkContacts"
                  class="flex-1"
                  :placeholder="i18n.global.t('projectNotes.noteEditor')"
                  @create:note="createNote"
                  @update:note="updatecontent"
                />
                <Button icon="pi pi-send" :loading="submitLoading" :disabled="clearHtml(noteInfo).trim().length === 0 ? true : submitLoading" @click="createNote" />
              </div>
            </div>
          </PermissionRole>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<style lang="css" scoped>
  .title-tab {
    min-height: 40px;
  }

  .off-screen {
    bottom: -80px;
    transition: bottom 0.3s;
  }
  .new-note-container {
    background-color: rgba(255, 255, 255, 0.3);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin-left: -1rem;
    margin-right: -1rem;
    width: calc(100% + 2rem);
    max-width: 32rem;
  }

  .container-reply {
    background-color: #83aff745;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    padding: 5px 10px;
    border-radius: 10px;
    /* #d1ddf1 */
    /* #83aff745 */
    /* border: 1px solid rgba(0, 0, 0, 0.2); */
  }

  @media only screen and (max-width: 991px) {
    .new-note-container {
      left: 0;
      right: 0;
      width: 100%;
      max-width: unset;
      margin-left: 0;
      margin-right: 0;
    }

    .off-screen.show {
      bottom: 0;
    }
  }

  @media only screen and (min-width: 992px) {
    .new-note-container {
      left: unset;
      right: unset;
      margin-left: -1rem;
      margin-right: -1rem;
      width: calc(100% + 2rem);
      max-width: 32rem;
    }

    .off-screen.show {
      bottom: 1rem;
    }
  }
</style>
