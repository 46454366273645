<script lang="ts" setup>
  import { ref, onMounted } from 'vue'
  import { storeToRefs } from 'pinia'
  import ProjectAssignCrewsAdd from '@/views/projects/assign_crews/ProjectAssignCrewsAdd.vue'
  import ProjectAssignCrewsUserAdd from '@/views/projects/assign_crews/ProjectAssignCrewsUserAdd.vue'
  import { i18n } from '@/i18n'
  import Button from 'primevue/button'
  import AssignedCrewCard from './AssignedCrewCard.vue'
  import PermissionRole from '@/components/PermissionRole/PermissionComponent.vue'
  import api from '@/services/api'
  import { useProjectStore, useBreakpointsStore, useUserStore } from '@/store'
  import ListUsers from './crewUsers/ListUsers.vue'
  import { ICrewUser } from './Types'
  import { useToast } from 'primevue/usetoast'
  import { watch } from 'vue'
  import { getPermission } from '@/components/PermissionRole/funtions'

  type Iprops = {
    projectId: string
  }

  const { t } = i18n.global
  const props = defineProps<Iprops>()
  const toast = useToast()
  const projectStore = useProjectStore()
  const userStore = useUserStore()
  const { profile, permissions } = storeToRefs(userStore)
  const { fetchAssignedCrews } = useProjectStore()
  const { projectAssignedCrew, project } = storeToRefs(projectStore)
  const assignedCrewsDialogIsActive = ref(false)
  const assignedCrewsUserDialogIsActive = ref(false)
  const deleteCrewDialogIsActive = ref(false)
  const deleteCrewUserDialogIsActive = ref(false)
  const loadingDeleteCrewUser = ref(false)
  const loadingDeleteCrew = ref(false)
  const crewUserSelect = ref({} as ICrewUser)
  const crewSelect = ref({} as ICrewUser)
  const crewCompanyId = ref<null | string>(null)
  const CrewUsersProject = ref<ICrewUser[]>([])
  const breakpointsStore = useBreakpointsStore()
  const { md } = storeToRefs(breakpointsStore)

  const openAssignedCrewsDialogIsActive = async () => {
    assignedCrewsDialogIsActive.value = true
  }

  const toggleDeleteAssingCrewUser = async () => {
    deleteCrewUserDialogIsActive.value = !deleteCrewUserDialogIsActive.value
  }

  const toggleDeleteAssingCrew = async () => {
    deleteCrewDialogIsActive.value = !deleteCrewDialogIsActive.value
  }

  const openAssignedCrewsUserDialogIsActive = async () => {
    assignedCrewsUserDialogIsActive.value = true
  }

  const getAllProjectCrews = async () => {
    await fetchAssignedCrews(props.projectId)
  }

  async function updateAssignedCrews() {
    assignedCrewsDialogIsActive.value = false
    await getAllProjectCrews()
  }

  const updateAssignedCrewsUser = async () => {
    assignedCrewsUserDialogIsActive.value = false
    await getCrewUsersProject({ alreadyIn: true, crewCompanyId: crewCompanyId.value })
  }

  watch(crewCompanyId, async (value, oldvalue) => {
    if (value !== oldvalue && value !== '') {
      getCrewUsersProject({ alreadyIn: true, crewCompanyId: value })
    }
  })

  onMounted(async () => {
    await getAllProjectCrews()
  })
  type paramsFilterType = { alreadyIn?: boolean; crewCompanyId?: string | null }

  const getCrewUsersProject = async (
    paramsFilter: paramsFilterType = {
      alreadyIn: true,
    }
  ) => {
    const params = Object.entries(paramsFilter)
      .filter(([, value]) => value !== undefined && value !== null)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')
    const response = await api.get(`/Api/CrewCompany/Project/${props.projectId}/Users?${params}`)
    CrewUsersProject.value = response.data.data
  }

  onMounted(async () => {
    if (getPermission(permissions.value, 'viewCrewUserWithinCrewManager', 'projectsCrewsPermissions')) return
    await getCrewUsersProject()
  })

  type paramsFilterTypeDelete = { alreadyIn?: boolean; crewCompanyId?: string | null }

  const removeCrewUserProject = async (crewUser: ICrewUser, paramsFilter: paramsFilterTypeDelete) => {
    try {
      loadingDeleteCrewUser.value = true
      const params = Object.entries(paramsFilter ?? {})
        .filter(([, value]) => value !== undefined && value !== null)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')
      await api.delete(`/Api/CrewCompany/Projects/${props.projectId}/RemoveUser/${crewUser.id}?${params}`)
      await getCrewUsersProject({ alreadyIn: true, crewCompanyId: crewCompanyId.value })
      await toggleDeleteAssingCrewUser()
      toast.add({ severity: 'success', summary: t('appLayout.titlesuccess'), detail: t('projectAssignCrewUsers.deletedCrewUserSuccess'), life: 3000 })
      loadingDeleteCrewUser.value = false
    } catch (error: any) {
      loadingDeleteCrewUser.value = false
      if (error.response?.data?.responseException?.exceptionMessage) {
        toast.add({ severity: 'error', summary: t('appLayout.titleunsuccess'), detail: error.response?.data?.responseException?.exceptionMessage, life: 3000 })
      }
    }
  }

  const removeCrewProject = async (crewUser: ICrewUser) => {
    try {
      loadingDeleteCrew.value = true
      await api.delete(`/Api/Project/${props.projectId}/ProjectCrewCompanies/${crewUser.id}`)
      await getAllProjectCrews()
      await toggleDeleteAssingCrew()
      loadingDeleteCrew.value = false
    } catch (error: any) {
      loadingDeleteCrew.value = false
      if (error.response?.data?.responseException?.exceptionMessage) {
        toast.add({ severity: 'error', summary: t('appLayout.titleunsuccess'), detail: error.response?.data?.responseException?.exceptionMessage, life: 3000 })
      }
    }
  }
</script>

<template>
  <div class="col-12">
    <div class="flex align-items-center justify-content-between title-tab">
      <div class="flex text-xl align-items-center">
        <div class="flex pr-2">
          <i class="pi pi-wrench" />
        </div>
        <div class="flex text-xl">
          {{ profile === 'Crew Manager' ? t('projectAssignCrewUsers.crews') : t('projectAssignCrews.crews') }}
        </div>
      </div>
      <PermissionRole v-if="!project.isClosed && project.isAuthorized" :nodo-name="'projectsCrewsPermissions'" :key-name="'assign'">
        <div class="flex">
          <Button :label="t('projectAssignCrews.assign')" icon="pi pi-plus" class="p-button-text" @click="openAssignedCrewsDialogIsActive" />
        </div>
      </PermissionRole>
      <PermissionRole v-if="!project.isClosed && project.isAuthorized" :nodo-name="'projectsCrewsPermissions'" :key-name="'assignCrewUser'">
        <div class="flex">
          <Button :label="t('projectAssignCrewUsers.assign')" icon="pi pi-plus" class="p-button-text" @click="openAssignedCrewsUserDialogIsActive" />
        </div>
      </PermissionRole>
    </div>
  </div>

  <div v-if="projectAssignedCrew.length > 0" class="ListCrewStyles">
    <template v-for="assignedCrew in projectAssignedCrew" :key="assignedCrew.id">
      <AssignedCrewCard
        :id="assignedCrew.id"
        :crew-company-id="assignedCrew.crewCompanyId"
        zuid="crew"
        :manager="assignedCrew.manager ?? {}"
        :name="assignedCrew.name"
        :rating-cache="assignedCrew.ratingCache"
        :rating-count="assignedCrew.ratingCount"
        :reviews="assignedCrew.reviews"
        :status="assignedCrew.status"
        :editable-reviews="assignedCrew.editable"
        @update-assigned-crews="updateAssignedCrews"
        @view-crew-user="(value) => (crewCompanyId = value.crewCompanyId)"
        @assing-crew-users="
          (value) => {
            crewCompanyId = value.crewCompanyId
            openAssignedCrewsUserDialogIsActive()
          }
        "
        @remove-user="
          (value) => {
            crewSelect = value.crewUser
            toggleDeleteAssingCrew()
          }
        "
      />
    </template>
  </div>

  <div v-else class="col-12">
    <div class="flex align-items-center justify-content-center flex-wrap col gap-2 h-20rem">
      <h6>{{ t('projectAssignCrews.noCrewAssignedYet') }}</h6>
    </div>
  </div>
  <PermissionRole :nodo-name="'projectsCrewsPermissions'" :key-name="'viewCrewUser'">
    <ListUsers
      :crew-users-project="CrewUsersProject"
      @remove-user="
        (value) => {
          crewUserSelect = value.crewUser
          toggleDeleteAssingCrewUser()
        }
      "
    />
  </PermissionRole>

  <Dialog
    v-model:visible="assignedCrewsDialogIsActive"
    :position="!md ? 'bottom' : undefined"
    class="w-full m-0"
    style="max-width: 42rem"
    :modal="true"
    :header="t('projectAssignCrews.assignCrew')"
    :draggable="false"
  >
    <ProjectAssignCrewsAdd :assigned-crews="projectAssignedCrew" :project-id="props.projectId" @update-assigned-crews="updateAssignedCrews" />
    <div
      :style="{
        marginBottom: !md ? 'calc(var(--sab) + 4rem) !important' : '',
      }"
    ></div>
  </Dialog>

  <Dialog
    v-model:visible="assignedCrewsUserDialogIsActive"
    :position="!md ? 'bottom' : undefined"
    class="w-full m-0"
    style="max-width: 42rem"
    :modal="true"
    :draggable="false"
    :header="t('projectAssignCrewUsers.myCrews')"
  >
    <ProjectAssignCrewsUserAdd
      :assigned-crews="projectAssignedCrew"
      :crew-company-id="crewCompanyId ?? undefined"
      :project-id="props.projectId"
      :users-assigns="CrewUsersProject"
      @update-assigned-crews="updateAssignedCrewsUser"
    />
    <div
      :style="{
        marginBottom: !md ? 'calc(var(--sab) + 4rem) !important' : '',
      }"
    ></div>
  </Dialog>
  <Dialog v-model:visible="deleteCrewUserDialogIsActive" class="w-full m-0" style="max-width: 42rem" :modal="true" :draggable="false">
    <template #header>
      <h5>{{ t('projectAssignCrews.removeCrew') }}</h5>
    </template>

    <div class="flex flex-column gap-2">
      <div style="font-size: 1rem; font-weight: 600; margin-bottom: 0.5rem">
        {{ t('projectAssignCrews.removeCrewUser') }}
      </div>
      <div class="flex flex-row gap-2">
        <Button :label="t('projectAssignCrews.buttonCancel')" class="p-button-text" @click="toggleDeleteAssingCrewUser" />
        <Button
          :label="t('projectAssignCrews.buttonRemove')"
          class="p-button-danger"
          :loading="loadingDeleteCrewUser"
          @click="removeCrewUserProject(crewUserSelect, { crewCompanyId })"
        />
      </div>
    </div>
  </Dialog>
  <Dialog v-model:visible="deleteCrewDialogIsActive" class="w-full m-0" style="max-width: 42rem" :modal="true" :draggable="false">
    <template #header>
      <h5>{{ t('projectAssignCrewUsers.removeCrew') }}</h5>
    </template>
    <div class="flex flex-column gap-2">
      <div style="font-size: 1rem; font-weight: 600; margin-bottom: 0.5rem">
        {{ t('projectAssignCrewUsers.removeCrewUser') }}
      </div>
      <div class="flex flex-row gap-2">
        <Button :label="t('projectAssignCrewUsers.buttonCancel')" class="p-button-text" @click="toggleDeleteAssingCrew" />
        <Button :label="t('projectAssignCrewUsers.buttonRemove')" class="p-button-danger" :loading="loadingDeleteCrew" @click="removeCrewProject(crewSelect)" />
      </div>
    </div>
  </Dialog>
</template>

<style tyle lang="scss" scoped>
  .ListCrewStyles {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    gap: 1rem;
  }
  .title-tab {
    min-height: 40px;
  }
</style>
