import { defineStore } from 'pinia'

export type TImpersonationId = {
  accessToken: string | null
  impersonationId: string | null
}
export type Tenant = {
  name: string
  clientName: string
}

export interface AccountStore {
  tenant: null | string
  isSignedIn: boolean
  accessToken: string | null
  refreshToken: string | null
  tokenExpiration: string | null
  accountStatus: AccountStatus | null
  userId: string | null
  loginAttempts: number
  lastAttempt: Date
  accessTokenAdmin?: string | null
  impersonation: TImpersonationId
  tenants: Tenant[]
}
export interface signInPayload {
  accessToken: string
  refreshToken: string
  expiration: string
  accountStatus: AccountStatus
  tenant: string
  userId: string
}

export enum AccountStatus {
  ACTIVE = 'ACTIVE',
  PENDING_PASSWORD = 'PENDING_PASSWORD',
}
export const useAccountStore = defineStore({
  id: 'Account',
  state: (): AccountStore => ({
    tenant: null,
    isSignedIn: false,
    accessToken: null,
    refreshToken: null,
    tokenExpiration: null,
    loginAttempts: 0,
    userId: null,
    lastAttempt: new Date(),
    accountStatus: null,
    impersonation: {
      accessToken: null,
      impersonationId: null,
    },
    accessTokenAdmin: null,
    tenants: [],
  }),
  getters: {
    getBearerAccessToken: (state): string => `Bearer ${state.accessToken}`,
    getTenant: (state): string | null => state.tenant,
  },
  actions: {
    setAttempts(attempts: number): void {
      this.loginAttempts = attempts
    },
    increaseLoginAttempts() {
      this.loginAttempts += 1
    },
    resetLoginAttempts() {
      this.lastAttempt = new Date()
      this.loginAttempts = 0
    },

    async signIn(payload: signInPayload) {
      try {
        this.accessToken = payload.accessToken
        this.refreshToken = payload.refreshToken
        this.tokenExpiration = payload.expiration
        this.tenant = payload.tenant
        this.accountStatus = payload.accountStatus
        this.isSignedIn = true
        this.userId = payload.userId
      } catch (err) {
        console.log('err')
        throw err
      }
    },
    changeTokenInAdmin(impersonation: TImpersonationId) {
      this.accessTokenAdmin = this.accessToken
      this.accessToken = impersonation?.accessToken
      this.impersonation = impersonation
    },
    changeTenants(tenants: Tenant[]) {
      this.tenants = tenants
    },
    backTokenInAdmin() {
      this.accessToken = `${this.accessTokenAdmin}`
      this.accessTokenAdmin = null
      this.impersonation = {
        accessToken: null,
        impersonationId: null,
      }
    },
  },
})
