<script lang="ts" setup>
  import PermissionRole from '@/components/PermissionRole/PermissionComponent.vue'
  import QueueCardProjectLead from '@/components/QueueCardProjectLead.vue'
  import UploadButtonComponent from '@/components/UploadButtonComponent.vue'
  import uploadNewQueuePhoto from '@/composables/uploadQueuePhoto'
  import { addNewIQueuePhotosCamera, IQueuePhoto, newIQueuePhoto, QueueStatusType } from '@/db/tables'
  import { i18n } from '@/i18n'
  import { IProblem } from '@/interfaces/problem/problem.interface'
  import api from '@/services/api'
  import { useBreakpointsStore, useProjectStore } from '@/store'
  import { base64ToBlob } from '@/utils/base64Toblob'
  import { delay } from '@/utils/delay'
  import devicePhotoTag from '@/utils/device-photo-tag'
  import { useDateFormatUtil } from '@/utils/useDateFormatUtil'
  import { captureException } from '@sentry/vue'
  import { storeToRefs } from 'pinia'
  import Button from 'primevue/button'
  import Message from 'primevue/message'
  import ProgressSpinner from 'primevue/progressspinner'
  import { useToast } from 'primevue/usetoast'
  import { onMounted, ref, toRefs } from 'vue'
  import DetailProblem from './DetailProblem.vue'
  import ProjectProblem from './ProjectProblem.vue'
  import ProjectProblemsAdd from './ProjectProblemsAdd.vue'

  const props = defineProps({ projectId: { type: String, required: true } })
  const breakpointsStore = useBreakpointsStore()
  const { md } = storeToRefs(breakpointsStore)
  const projectStore = useProjectStore()
  const { setProjectProblems } = projectStore
  const { project } = storeToRefs(projectStore)
  const queueStore = uploadNewQueuePhoto()
  const { uploadQueuePhotos } = queueStore
  const { queue } = toRefs(queueStore)
  const problems = ref<IProblem[]>([])
  const problemItem = ref<any>()
  const createOpen = ref(false)
  const showProblem = ref(false)
  const showDialogClose = ref(false)
  const problemId = ref<string | null>(null)
  const toast = useToast()
  const loading = ref(false)
  const { t } = i18n.global

  function toggleCreateDialog() {
    createOpen.value = !createOpen.value
  }

  async function fetchProblems() {
    try {
      const {
        data: { data: resp },
      } = await api.get(`/Api/Projects/${props.projectId}/Problems`)
      problems.value = [...resp]
      setProjectProblems(resp)
    } catch (err) {
      captureException(err)
      console.log('err', err)
    }
  }

  async function updateProblems() {
    fetchProblems()
    // problems.value = [...problems.value, resp]
    // setProjectProblems([...problems.value, resp])
    createOpen.value = false
  }

  async function updateProblem(editedProblem: any) {
    const problem = problems.value.find((p) => p.id === editedProblem.id)
    if (!problem) return

    problem.problemStatus = editedProblem.problemStatus
    setProjectProblems(problems.value)
  }

  function removeProblem(id: string) {
    problems.value = problems.value.filter((p) => p.id !== id)
    setProjectProblems(problems.value)
  }

  onMounted(async () => {
    await fetchProblems()
  })

  const handleShowProblem = (problem: any) => {
    showProblem.value = true
    problemItem.value = problem
  }

  const handleHideProblem = () => {
    showProblem.value = false
    problemItem.value = null
  }
  const handleShowCloseProblem = (id: string) => {
    problemId.value = id
    showDialogClose.value = true
  }

  async function markClosed() {
    try {
      const payload = { problemStatus: 'CLOSED', photoAfterIds: [] }

      const {
        data: { data: resp },
      } = await api.patch(`/Api/Problem/${problemId.value}`, payload)
      showDialogClose.value = false
      updateProblem(resp)
      toast.add({ severity: 'success', summary: t('appLayout.titlesuccess'), detail: i18n.global.t('projectProblem.successMarkedClosed'), life: 3000 })
    } catch (err) {
      captureException(err)
      console.log('err', err)
      toast.add({ severity: 'error', summary: t('appLayout.titleunsuccess'), detail: i18n.global.t('projectProblem.errorMarkedClosed'), life: 3000 })
    }
  }
  const handleUploadImages = async (photos: IQueuePhoto[]) => {
    loading.value = true
    try {
      const uploadPhotoType = await devicePhotoTag('UPLOAD')
      const newPhotos = photos
        .filter((media) => media.type_media === 'IMAGE')
        .map((photo) => {
          const blob = base64ToBlob(`${photo.base64}`, 'image/jpeg')
          const photoData: newIQueuePhoto = {
            id: photo.id,
            projectId: photo.projectId,
            entityName: project.value.projectName,
            poNumberProject: project.value?.poNumber ?? '',
            latitude: photo.latitude,
            longitude: photo.longitude,
            entityType: 'Project',
            photoType: photo.photoType,
            photoTypeId: problemId.value ?? '',
            file: blob,
            status: 'IDLE' as QueueStatusType,
            date: new Date(),
            errorMessage: '',
            retries: 0,
            uploadPhotoType: uploadPhotoType,
          }
          return { ...photoData }
        })
      await addNewIQueuePhotosCamera(newPhotos)
      await delay(1000)
      await uploadQueuePhotos()
      await markClosed()
      await fetchProblems()
    } catch (error) {
      console.log(error)
    } finally {
      loading.value = false
    }
  }
</script>

<template>
  <div v-if="!showProblem" class="col-12">
    <div class="flex align-items-center justify-content-between title-tab">
      <div class="flex text-xl align-items-center">
        <div class="flex pr-2">
          <i class="pi pi-wrench" />
        </div>
        <div class="flex text-xl">{{ $t('projectProblems.problems') }}</div>
      </div>

      <PermissionRole v-if="!project.isClosed" nodo-name="projectsProblemsPermissions" key-name="create">
        <div class="flex">
          <Button :label="i18n.global.t('projectProblems.create')" icon="pi pi-plus" class="p-button-text" @click="toggleCreateDialog" />
        </div>
      </PermissionRole>
    </div>
  </div>

  <div v-if="!showProblem" class="grid w-full">
    <ProjectProblem
      v-for="problem in problems"
      :key="problem.id"
      :problem="problem"
      :project-id="props.projectId"
      @update-problem="updateProblem"
      @remove-problem="removeProblem"
      @show-problem="handleShowProblem"
      @show-close-problem="() => handleShowCloseProblem(problem.id)"
    />
  </div>

  <div v-else>
    <DetailProblem :problem="problemItem" :project="project" @hide-problem="handleHideProblem"></DetailProblem>
  </div>
  <Dialog
    v-model:visible="showDialogClose"
    :position="!md ? 'bottom' : undefined"
    class="w-full m-0 hide-on-camera"
    style="max-width: 42rem"
    :modal="true"
    :draggable="false"
    :header="t('projectProblems.dialog.problem.close.header')"
  >
    <Message :closable="false">{{ t('projectProblems.dialog.problem.close.details') }}</Message>
    <p>
      <strong>{{ t('projectProblems.dialog.problem.close.assignedto') }}: </strong>{{ problems?.find((e) => e?.id === problemId)?.assignedTo?.fullName }}
    </p>
    <p>
      <strong>{{ t('projectProblems.dialog.problem.close.date') }}: </strong>{{ useDateFormatUtil(problems?.find((e) => e?.id === problemId)?.assignedDate, 'MMM D, YYYY h:mm A') }}
    </p>
    <p>
      <strong>{{ t('projectProblems.dialog.problem.close.description') }}: </strong
      >{{ problems?.find((e) => e?.id === problemId)?.description?.trim()?.length ? problems?.find((e) => e?.id === problemId)?.description?.trim() : 'No description' }}
    </p>

    <div
      v-if="(queue ?? []).length > 0"
      class="grid align-content-start w-full h-10rem p-2 flex-column mb-3 align-items-srart gap-2"
      :class="(queue ?? []).length > 3 && 'custombar overflow-x-scroll'"
    >
      <QueueCardProjectLead v-for="photo in queue || []" :key="photo.id" :photo="photo" />
    </div>
    <div class="flex align-items-center justify-content-center">
      <ProgressSpinner
        v-if="loading"
        style="width: 25px; height: 25px"
        stroke-width="5"
        animation-duration="1s"
        :pt="{ circle: { style: { stroke: '#2563eb', animation: 'p-progress-spinner-dash 1.5s ease-in-out infinite' } } }"
      />
      <UploadButtonComponent
        v-else
        :rounded-button="false"
        entity-type="Project"
        photo-type="PROBLEM"
        :project-id="props.projectId"
        :label-button="t('projectProblems.dialog.problem.close.uploadImages')"
        class-button="p-button-text ButtonInputFile"
        @on-media="handleUploadImages"
      />
    </div>
  </Dialog>
  <Dialog
    v-model:visible="createOpen"
    :position="!md ? 'bottom' : undefined"
    class="w-full m-0 hide-on-camera"
    style="max-width: 42rem"
    :modal="true"
    :draggable="false"
    :header="t('projectProblems.createAProblem')"
  >
    <ProjectProblemsAdd :problems="problems" :project-id="props.projectId" @update-problems="updateProblems" />
    <div
      :style="{
        marginBottom: !md ? 'calc(var(--sab) + 4rem) !important' : '',
      }"
    ></div>
  </Dialog>
</template>

<style lang="scss" scoped>
  .title-tab {
    min-height: 40px;
  }
</style>
<style lang="scss">
  .layout-save {
    padding-bottom: calc(var(--sab) + 1rem) !important;
  }
</style>
