<script lang="ts" async setup>
  import { useUserInfo } from '@/composables/useUserInfo'
  import { baseUrl } from '@/constants'
  import { i18n } from '@/i18n'
  import { checkIsBiometricAvailable, getCredentialsNative, setCredentialsNative, verifyIndentityNative } from '@/services/biometric'
  import { AccountStatus, signInPayload, useAccountStore, useLoaderStore } from '@/store'
  import { Capacitor } from '@capacitor/core'
  import { Haptics, ImpactStyle } from '@capacitor/haptics'
  import * as Sentry from '@sentry/vue'
  import { useVuelidate } from '@vuelidate/core'
  import { required } from '@vuelidate/validators'
  import axios from 'axios'
  import { SavePassword } from 'capacitor-ios-autofill-save-password'
  // import { Credentials } from 'capacitor-native-biometric'
  import { storeToRefs } from 'pinia'
  const { t } = i18n.global
  import { onMounted, reactive, ref, Ref, watch } from 'vue'
  import { useRouter } from 'vue-router'

  const loaderStore = useLoaderStore()
  const { getInfoUserFirstTime } = useUserInfo()
  const { setLoaderActiveState } = loaderStore
  const router = useRouter()
  setLoaderActiveState(false)

  type ILoginResponse = {
    accessToken: string
    refreshToken: string
    expiration: string
    accountStatus: AccountStatus
    tenants: {
      clientName: string
      name: string
    }[]
    userId: string
  }

  interface IPayload {
    username: string
    password: string
    tenant: string | null
  }

  interface tenantDropdownValue {
    clientName: string
    name: string
  }

  interface IField {
    value?: string
    labelText: string
    isDisabled: boolean
  }

  interface ITenantSelection {
    value: tenantDropdownValue | null
    labelText: string
    tenants: any[]
    isActive: boolean
  }

  interface IButton {
    labelText: string
    icon: string
    isActive: boolean
    isLoading: boolean
    isDisabled: boolean
  }

  interface ISignInOptions {
    username: IField
    password: IField
    tenantSelection: ITenantSelection
    submit_button: IButton
    cancel_button: IButton
  }

  const loginForm: ISignInOptions = reactive({
    username: {
      labelText: i18n.global.t('loginIndex.userNameOrEmail'),
      isDisabled: false,
    },
    password: {
      labelText: i18n.global.t('loginIndex.password'),
      isActive: false,
      isDisabled: false,
    },
    tenantSelection: {
      labelText: i18n.global.t('loginIndex.selectATenant'),
      tenants: [],
      value: null as tenantDropdownValue | null,
      isActive: false,
    },
    submit_button: {
      labelText: i18n.global.t('loginIndex.signIn'),
      icon: 'pi pi-sign-in',
      isActive: false,
      isLoading: false,
      isDisabled: false,
    },
    cancel_button: {
      labelText: i18n.global.t('loginIndex.cancel'),
      icon: 'pi pi-times',
      isActive: false,
      isLoading: false,
      isDisabled: false,
    },
  })
  const rules = {
    username: { value: { required } },
    password: { value: { minLength: 0 } },
  }

  const v$ = useVuelidate(rules, loginForm)
  const errorAttemps: Ref<null | string> = ref(null)
  const errorResponseAPI: Ref<null | string> = ref(null)
  const loginResponse = ref<ILoginResponse | null>(null)
  const accountStore = useAccountStore()
  const { loginAttempts } = storeToRefs(accountStore)
  const { signIn, increaseLoginAttempts, setAttempts, resetLoginAttempts, changeTenants } = accountStore
  if (JSON.parse(localStorage.getItem('accountState') as string)) {
    if (JSON.parse(localStorage.getItem('accountState') as string).loginAttempts >= 3) {
      setAttempts(JSON.parse(localStorage.getItem('accountState') as string).loginAttempts)
    }
  }

  const isValidatedIdentityNative = ref(false)
  const faceIdDetected = ref(false)
  watch(
    () => loginAttempts.value,
    () => {
      bruteForceLoginProtection()
    },
    { immediate: true }
  )

  watch(isValidatedIdentityNative, async (currentIdentity) => {
    if (currentIdentity) {
      const nativeCredentials = await getCredentialsNative()
      if (nativeCredentials?.status === 'MOBILE' && nativeCredentials?.credentials !== null) {
        loginForm.username.value = nativeCredentials?.credentials?.username
        loginForm.password.value = nativeCredentials?.credentials?.password
        handleSubmit(!v$.value.$invalid)
      }
    }
  })
  const handleFaceId = async () => {
    const nativeCredentials = await getCredentialsNative()
    if (nativeCredentials?.status === 'MOBILE' && nativeCredentials?.credentials !== null) {
      const result = await verifyIndentityNative(nativeCredentials?.credentials?.username)
      // if (!isValidatedIdentityNative.value && result) {
      isValidatedIdentityNative.value = result
      // }
    }
  }

  onMounted(async () => {
    const result = await checkIsBiometricAvailable()
    if (result) {
      const nativeCredentials = await getCredentialsNative()
      if (nativeCredentials?.status === 'MOBILE' && nativeCredentials?.credentials !== null) {
        faceIdDetected.value = true
      }
    }

    bruteForceLoginProtection()
  })

  async function cancelLogin() {
    loginForm.username.value = ''
    loginForm.username.isDisabled = false
    loginForm.password.value = ''

    loginForm.cancel_button.isLoading = false
    loginForm.cancel_button.isActive = false

    loginForm.tenantSelection.tenants = []
    loginForm.tenantSelection.value = null
    loginForm.tenantSelection.isActive = false
  }

  async function handleSubmit(isFormValid: boolean) {
    errorAttemps.value = null
    errorResponseAPI.value = null
    try {
      await v$.value.$validate()
      loginForm.submit_button.isLoading = true

      if (Capacitor.isNativePlatform()) await Haptics.impact({ style: ImpactStyle.Medium })
      console.log('isFormValid', isFormValid)
      if (!isFormValid) {
        loginForm.submit_button.isLoading = false
        errorAttemps.value = i18n.global.t('loginIndex.credentialsAreRequired')
        return
      }

      if (bruteForceLoginProtection()) return

      if (loginForm.username.value && loginForm.password.value) {
        const payload: IPayload = {
          username: loginForm.username.value.toLowerCase()?.trim(),
          password: loginForm.password.value,
          tenant: null,
        }

        if (loginForm.tenantSelection?.value !== null && loginResponse.value !== null) {
          const signInStore = {
            accessToken: loginResponse.value.accessToken,
            refreshToken: loginResponse.value.refreshToken,
            expiration: loginResponse.value.expiration,
            accountStatus: loginResponse.value.accountStatus,
            tenant: loginForm.tenantSelection.value.clientName,
            userId: loginResponse.value.userId,
          }
          await signIn(signInStore)
          await getInfoUserFirstTime()
          resetLoginAttempts()
          errorAttemps.value = null
          await router.push('/')
        } else {
          const {
            data: { data: response },
          } = await axios.post(`${baseUrl}/Api/Login`, payload)

          if (response.tenants.filter((r: any) => r.clientName !== 'api-admin').length == 0) {
            errorAttemps.value = i18n.global.t('loginIndex.yourAccountIsNotAssigned')
            return
          }

          if (response.tenants.length > 1) {
            loginResponse.value = response
            loginForm.username.isDisabled = true
            loginForm.tenantSelection.isActive = true
            loginForm.tenantSelection.tenants = response.tenants
            loginForm.cancel_button.isActive = true
            changeTenants(response.tenants)
            resetLoginAttempts()
            return
          }

          const signInStore: signInPayload = {
            accessToken: response.accessToken,
            refreshToken: response.refreshToken,
            expiration: response.expiration,
            accountStatus: response.accountStatus,
            tenant: response.tenants[0].clientName,
            userId: response.userId,
          }

          await signIn(signInStore)
          await getInfoUserFirstTime()
          if (Capacitor.getPlatform() === 'ios') await SavePassword.promptDialog({ username: payload.username, password: payload.password })

          resetLoginAttempts()
          errorAttemps.value = null
          if (response.accountStatus == AccountStatus.PENDING_PASSWORD) {
            await router.push('/account/change-password')
            return
          }
          ///Biometric authentication login in mobile
          await setCredentialsNative(payload?.username, payload?.password)

          await router.push('/')
        }
      }
    } catch (err: any) {
      errorResponseAPI.value = err?.response?.data?.responseException?.exceptionMessage?.message
      Sentry.captureException(err)
      increaseLoginAttempts()
      errorAttemps.value = `${i18n.global.t('loginIndex.invalidCredentials')} ${5 - loginAttempts.value} ${i18n.global.t('loginIndex.attemptsLeft')}`
    } finally {
      loginForm.submit_button.isLoading = false
    }
  }
  function bruteForceLoginProtection(): boolean {
    if (loginAttempts.value > 4) {
      loginForm.submit_button.isDisabled = true
      errorAttemps.value = `${loginAttempts.value} ${i18n.global.t('loginIndex.attemptsWait5Minutes')}`
      loginForm.password.value = ''
      loginForm.username.value = ''
      loginForm.username.isDisabled = true
      loginForm.password.isDisabled = true
      const timeout = 1000 * 60 * 5

      setTimeout(() => {
        loginAttempts.value = 0
        errorAttemps.value = null
        loginForm.username.isDisabled = false
        loginForm.password.isDisabled = false
        loginForm.submit_button.isDisabled = false
      }, timeout)
      return true
    }
    return false
  }
  // const visible = ref(true)
</script>

<template>
  <div class="grid m-0 p-0" style="background-image: url('/assets/img/bg/bg1.webp')">
    <!-- <Dialog v-model:visible="visible" :draggable="false" modal :header="t()" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
      <p class="m-0">
        {{ error?.error }}
      </p>
    </Dialog> -->
    <div class="col h-screen m-0 p-0">
      <div class="flex align-items-center justify-content-center h-screen">
        <Card class="border-round card-dimensions">
          <template #header>
            <div class="text-center mb-4">
              <img src="/assets/icon/logo.png" alt="Image" height="90" class="w-auto mt-4" style="margin-bottom: -14px" />
              <div class="text-900 text-3xl font-medium line-height-1">
                {{ $t('loginIndex.production') }}
              </div>
              <span class="text-600 font-normal line-height-1">{{ $t('loginIndex.byInfinityHomeServices') }}</span>
            </div>
          </template>

          <template #content>
            <Message v-if="errorAttemps" severity="error" :closable="false" icon="pi pi-lock">{{ errorAttemps }} </Message>
            <Message v-if="errorResponseAPI" severity="error" :closable="false" icon="pi pi-lock">{{ errorResponseAPI }} </Message>
            <form class="w-full" @submit.prevent="handleSubmit(!v$.$invalid)">
              <div class="p-inputtext p-0 w-full p-input-icon-left w-full mb-3 flex flex-row align-items-center justify-content-center">
                <div class="flex align-items-center justify-content-center w-1 pl-2">
                  <i class="pi pi-user" />
                </div>
                <InputText
                  v-model="loginForm.username.value"
                  inputmode="email"
                  class="w-full border-0"
                  :disabled="loginForm.username.isDisabled"
                  :placeholder="loginForm.username.labelText"
                  :class="{ 'p-invalid': v$.username.$errors.length > 0 }"
                  autocomplete="email"
                  name="username"
                  autocapitalize="off"
                />
              </div>

              <div class="p-input-icon-left w-full mb-3">
                <Password
                  v-model="loginForm.password.value"
                  toggle-mask
                  :class="{ 'p-invalid': v$.password.$errors.length > 0 }"
                  class="w-full"
                  input-class="w-full"
                  :feedback="false"
                  :input-props="{ disabled: loginForm.password.isDisabled }"
                  :placeholder="loginForm.password.labelText"
                  autocomplete="password"
                  name="password"
                />
              </div>

              <div v-if="loginForm.tenantSelection.isActive" class="w-full mb-3">
                <Dropdown
                  v-model="loginForm.tenantSelection.value"
                  class="w-full"
                  :filter="true"
                  :placeholder="loginForm.tenantSelection.labelText"
                  :options="loginForm.tenantSelection.tenants"
                >
                  <template #value="slotProps">
                    <div v-if="slotProps.value" class="p-dropdown-car-option">
                      <div class="flex w-full align-items-center">
                        <img height="20" src="/assets/icon/logo.png" />
                        <span class="pl-2">{{ slotProps.value.name }}</span>
                      </div>
                    </div>
                  </template>

                  <template #option="slotProps">
                    <div class="p-dropdown-car-option">
                      <div class="flex w-full align-items-center">
                        <img height="20" src="/assets/icon/logo.png" />
                        <span class="pl-2">{{ slotProps.option.name }}</span>
                      </div>
                    </div>
                  </template>
                </Dropdown>
              </div>

              <div class="flex gap-2">
                <Button
                  v-if="loginForm.cancel_button.isActive"
                  class="w-full p-button-danger"
                  icon-pos="left"
                  :icon="loginForm.cancel_button.icon"
                  :label="loginForm.cancel_button.labelText"
                  :loading="loginForm.cancel_button.isLoading"
                  :disabled="loginForm.cancel_button.isDisabled"
                  @click="cancelLogin"
                />
                <Button
                  type="submit"
                  class="w-full"
                  icon-pos="right"
                  :icon="loginForm.submit_button.icon"
                  :label="loginForm.submit_button.labelText"
                  :loading="loginForm.submit_button.isLoading"
                  :disabled="loginForm.submit_button.isDisabled"
                />
              </div>
              <Button
                v-if="faceIdDetected"
                type="button"
                class="w-full mt-2"
                severity="secondary"
                icon-pos="right"
                :icon="loginForm.submit_button.icon"
                :label="t('loginIndex.FACEID')"
                :loading="loginForm.submit_button.isLoading"
                :disabled="loginForm.submit_button.isDisabled"
                @click="handleFaceId"
              />
            </form>
          </template>

          <template #footer>
            <div class="flex justify-content-between">
              <div class="text-right">
                <a class="font-medium no-underline text-color-secondary text-right cursor-pointer" href="/account/policies">{{ $t('loginIndex.policies') }}</a>
              </div>
              <div class="text-right">
                <span class="font-medium no-underline text-blue-500 text-right cursor-pointer" @click="router.push('/account/forgot-password')">{{
                  $t('loginIndex.forgotPassword')
                }}</span>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .card-dimensions {
    min-width: 450px;
    max-width: 450px;
    @media (max-width: 470px) {
      min-width: 90%;
      width: 90%;
    }
  }
  .grid {
    background-position: center;
    background-size: cover;
    background-repeat: repeat;
  }
</style>
