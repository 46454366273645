<!-- eslint-disable no-const-assign -->
<!-- eslint-disable @typescript-eslint/no-empty-function -->
<script lang="ts" setup>
  import api from '@/services/api'
  import { computed, onMounted, onUnmounted, ref, type Ref, watch } from 'vue'
  import { storeToRefs } from 'pinia'
  import ProjectPunchListAdd from '@/views/projects/punch_list/ProjectPunchListAdd.vue'
  import { i18n } from '@/i18n'
  import { useBreakpointsStore, useCloseOutStore, useProjectStore, useUserStore } from '@/store'
  import Accordion from 'primevue/accordion'
  import AccordionTab from 'primevue/accordiontab'
  import TasksAdd from './TasksAdd.vue'
  import ListItemComponent from '@/components/PunchListCamera/SideBar/ListTaskComponent/TaskItem.vue'
  import PermissionRole from '@/components/PermissionRole/PermissionComponent.vue'
  import { ITask } from '@/interfaces/punch-list/task/task.interface'
  import { IActivePunchList, IPunchList } from '@/interfaces/punch-list/punch-list.interface'
  import { useToast } from 'primevue/usetoast'
  import { ITaskList } from '@/interfaces/punch-list/task-list/task-list.interface'
  import { delay } from '@/utils/delay'
  import { captureException } from '@sentry/vue'

  const { t } = i18n.global
  const projectStore = useProjectStore()
  const { fetchProjectPunchList, setActivePunchList, setActiveTaskListId, resetProjectPunchList } = projectStore
  const { projectPunchList, loadingProjectPunchList, activePunchList, activeTaskListId, project } = storeToRefs(projectStore)
  const breakpointsStore = useBreakpointsStore()
  const { md } = storeToRefs(breakpointsStore)
  const toast = useToast()
  const props = defineProps({ projectId: { type: String, required: true } })
  const punchListDialogIsActive: Ref<boolean> = ref(false)
  const isEditPunchlist = ref(false)
  const isEditTask = ref(false)
  const loadingDeleting = ref(false)
  const tasksDialogIsActive: Ref<boolean> = ref(false)
  const showDialogDeletePunchlist = ref(false)
  const activePunchListIndex = ref<undefined | never[]>(undefined)
  const indexMenuPunchlist: Ref<number | null> = ref(null)
  const payloadPuchlist = ref({})
  const payloadTask = ref([])
  const indexPunchlist = ref()

  type IPunchStore = {
    punchlist: IPunchList | null
    task: ITask | null
    taskList: ITaskList | null
    type: 'PUNCHLIST' | 'TASKLIST' | 'TASK' | 'NONE'
  }
  const punchlistDialogState = ref<IPunchStore>({
    punchlist: null,
    taskList: null,
    task: null,
    type: 'NONE',
  })
  const { id } = useUserStore()
  const isSubmittingTask = ref<boolean>(false)
  const isSubmittingPunchlist = ref<boolean>(false)
  const punchListCameraStore = useCloseOutStore()
  const { isActive: isPunchListActive } = storeToRefs(punchListCameraStore)

  const activateTaskListDialog = async (tab: any) => {
    indexPunchlist.value = tab.index
    indexMenuPunchlist.value = null
    const punchlist = projectPunchList.value[tab.index]
    await getPunchList(punchlist.id)
  }

  function clearActivePunchList() {
    setTimeout(() => {
      indexMenuPunchlist.value = null
      setActivePunchList(null)
    }, 300)
  }

  const createTask = async (payload: IPunchList) => {
    try {
      isSubmittingTask.value = true
      await api.post('/Api/Projects/PunchLists/TaskLists/Tasks', {
        details: '',
        photoIds: [],
        instructions: '',
        isPhotoRequired: false,
        taskListId: payload.taskListId,
        title: t('projectPunchListAdd.taskCreate'),
      })
      refreshAll()
      isSubmittingTask.value = false
      toast.add({ severity: 'success', summary: t('appLayout.titlesuccess'), detail: 'Successfully added task', life: 3000 })
    } catch (e) {
      captureException(e)
      toast.add({ severity: 'error', summary: t('appLayout.titleunsuccess'), detail: `${e}`, life: 3000 })
      isSubmittingTask.value = false
    }
  }

  const createPunchList = async () => {
    try {
      isSubmittingPunchlist.value = true
      const payload = {
        description: '',
        taskLists: [],
        projectId: props.projectId,
        assignedToId: id,
        name: t('projectPunchListAdd.punchListCreate'),
      }
      await api.post('/Api/Projects/PunchListTree', payload)
      await fetchProjectPunchList(props.projectId)
      toast.add({ severity: 'success', summary: t('projectPunchListAdd.success'), detail: t('projectPunchListAdd.punchListCreated'), life: 3000 })
      activePunchListIndex.value = []
      isSubmittingPunchlist.value = false
    } catch (e) {
      captureException(e)
      toast.add({ severity: 'error', summary: t('appLayout.titleunsuccess'), detail: `${e}`, life: 3000 })
      isSubmittingPunchlist.value = false
    }
  }

  const getPunchList = async (id: string) => {
    const {
      data: { data: resp },
    } = await api.get(`/Api/Projects/PunchLists/${id}`)
    await setActivePunchList(resp)
    return resp
  }

  const refreshAll = async () => {
    fetchProjectPunchList(props.projectId)
    const punchlist = projectPunchList.value[indexPunchlist.value]
    await getPunchList(punchlist.id)
  }

  function closeAddDialog() {
    isEditPunchlist.value = false
    punchListDialogIsActive.value = false
    fetchProjectPunchList(props.projectId)
  }

  const closeAddTasksDialog = async () => {
    isEditTask.value = false
    tasksDialogIsActive.value = false
    if (activePunchList.value) {
      await delay(2000)
      getPunchList(activePunchList.value.id)
    }
  }

  const activatePunchListDialog = (payload: IPunchList) => {
    payloadPuchlist.value = payload
    punchListDialogIsActive.value = true
  }

  const handleAPIDeleteGeneral = async () => {
    const type = punchlistDialogState.value?.type
    try {
      loadingDeleting.value = true
      if (type === 'PUNCHLIST') {
        const punchListId = punchlistDialogState.value?.punchlist?.id
        if (!punchListId) return
        await api.delete(`/Api/Projects/PunchLists/${punchListId}`)
        projectPunchList.value = projectPunchList.value.filter((e) => e.id !== punchListId)
        toast.add({
          severity: 'success',
          summary: t('appLayout.titlesuccess'),
          detail: t('projectPunchList.punchlistDeletedSuccessfully'),
          life: 3000,
        })
      }
      if (type === 'TASK') {
        const taskId = punchlistDialogState.value?.task?.id
        const taskListId = punchlistDialogState.value?.taskList?.id
        if (!taskId) return
        await api.delete(`/Api/Projects/PunchLists/TaskLists/Tasks/${taskId}`)
        activePunchList.value = {
          ...(activePunchList.value ?? ({} as IActivePunchList)),
          taskLists:
            activePunchList.value?.taskLists?.map((e) =>
              e.id === taskListId
                ? {
                    ...e,
                    tasks: e?.tasks?.filter((task) => task.id !== taskId),
                  }
                : e
            ) ?? [],
        }
        toast.add({
          severity: 'success',
          summary: t('appLayout.titlesuccess'),
          detail: t('projectPunchList.taskDeletedSuccessfully'),
          life: 3000,
        })
      }

      showDialogDeletePunchlist.value = false
    } catch (e: any) {
      const errorMessage = e?.response?.data?.responseException?.exceptionMessage?.message
      toast.add({
        severity: 'error',
        summary: t('appLayout.titleunsuccess'),
        detail: errorMessage ?? '',
        life: 3000,
      })
    } finally {
      loadingDeleting.value = false
    }
  }

  const dialogModal = computed(() => {
    if (punchlistDialogState.value.type === 'PUNCHLIST') {
      return {
        title: t('projectPunchList.deletePunchlist'),
        description: t('projectPunchList.deletePunchlistDescription'),
        entity: punchlistDialogState.value.punchlist?.name,
      }
    }
    return {
      title: t('projectPunchList.deleteTask'),
      description: t('projectPunchList.deleteTaskDescription'),
      entity: punchlistDialogState.value.task?.title,
    }
  })

  watch(isPunchListActive, async (newValue, oldValue) => {
    if (!newValue && oldValue) {
      await fetchProjectPunchList(props.projectId)
      if (activePunchList.value) {
        activePunchList.value = await getPunchList(activePunchList.value.id)
      }
    }
  })

  const handleEditTask = (task?: any) => {
    tasksDialogIsActive.value = true
    isEditTask.value = true
    payloadTask.value = task
  }

  const totalPhotos = computed(() => {
    return activePunchList.value?.tasks.reduce((count, task) => count + (task.photos?.length || 0), 0)
  })

  const handleDelete = async (punchList: IPunchList) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this punchlist?')
    if (!confirmDelete) return
    await api.delete(`/Api/Projects/PunchLists/${punchList.id}`)
    toast.add({
      severity: 'success',
      summary: t('appLayout.titlesuccess'),
      detail: t('projectPunchList.deletePunchlist'),
      life: 3000,
    })
    fetchProjectPunchList(props.projectId)
    activePunchListIndex.value = []
    clearActivePunchList()
  }

  onMounted(() => {
    fetchProjectPunchList(props.projectId)
  })

  onUnmounted(() => {
    setActivePunchList(null)
    setActiveTaskListId(null)
    resetProjectPunchList()
  })

  function onChangePunchlist(visible: boolean) {
    if (!visible) {
      isEditPunchlist.value = false
      punchListDialogIsActive.value = false
    }
  }

  function onChangeTask(visible: boolean) {
    if (!visible) {
      isEditTask.value = false
      tasksDialogIsActive.value = false
    }
  }
</script>

<template>
  <div class="col-12">
    <div class="flex align-items-center justify-content-between title-tab" style="display: flex; flex-direction: row">
      <div class="flex text-xl align-items-center" style="flex: 1; display: flex; padding-top: 15px; width: 100%">
        <div class="flex pr-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard-check" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
            <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
            <path
              d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"
            />
          </svg>
        </div>
        <div class="flex text-xl">
          {{ $t('projectPunchList.punchLists') }}
        </div>
      </div>
      <PermissionRole v-if="!project.isClosed && project.isAuthorized" :nodo-name="'projectsPunchListsPermissions'" :key-name="'createPunchList'">
        <div class="flex justify-content-center align-items-center" style="width: 35px; height: 35px; border-radius: 10px">
          <Button
            :style="{
              width: '100%',
              height: '100%',
              backgroundColor: 'black',
              borderColor: 'black',
              padding: '10px',
            }"
            @click="createPunchList"
          >
            <template v-if="isSubmittingPunchlist">
              <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" class="loaderButtonPunchlist">
                <circle cx="25" cy="25" r="20" stroke="white" stroke-width="4" fill="none" stroke-dasharray="31.4" stroke-dashoffset="0" />
              </svg>
            </template>
            <template v-else>
              <svg viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet">
                <path
                  fill="white"
                  d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32v144H48c-17.7 0-32 14.3-32 32s14.3 32 32 32h144v144c0 17.7 14.3 32 32 32s32-14.3 32-32V288h144c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                />
              </svg>
            </template>
          </Button>
        </div>
      </PermissionRole>
    </div>
  </div>

  <div v-if="projectPunchList.length > 0" class="col-12">
    <Accordion :multiple="false" :active-index="activePunchListIndex" @tab-open="activateTaskListDialog" @tab-close="clearActivePunchList">
      <AccordionTab v-for="punchList in projectPunchList" :key="punchList.id">
        <template #header>
          <div class="flex flex-column w-full" style="gap: 10px">
            <div class="flex w-full justify-content-between align-items-center">
              <div class="flex">
                <div style="font-size: 15px; color: black" @click.stop="activatePunchListDialog(punchList)">{{ punchList.name }}</div>
              </div>
              <PermissionRole :nodo-name="'projectsPunchListsPermissions'" :key-name="'complete'">
                <div v-if="punchList.percentageCompleted === 100" class="flex" style="background-color: #1cd26d; padding: 4px; border-radius: 6px; right: 20px; position: absolute">
                  <span style="color: white; font-size: 10px">
                    {{ t('projectPunchList.completed') }}
                  </span>
                </div>
              </PermissionRole>
            </div>
            <div class="flex w-full flex-wrap">
              <div style="flex-direction: row; width: 100%; display: flex; align-content: center; align-items: center">
                <ProgressBar :value="punchList.percentageCompleted" style="height: 0.8rem; flex: 1" />
              </div>
              <div class="flex w-full justify-content-between" style="padding-top: 10px">
                <div style="font-size: 11px; color: gray">
                  {{ punchList.assignedTo.fullName }}
                </div>
              </div>
            </div>
          </div>
        </template>
        <div v-if="activePunchList && activePunchList.id === punchList.id">
          <div class="grid">
            <div style="display: flex; padding: 10px 10px 20px; gap: 10px; flex: 1; justify-content: space-between">
              <div style="display: flex; gap: 10px">
                <div style="display: flex; align-items: center">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="15" height="15">
                    <path
                      d="M448 80c8.8 0 16 7.2 16 16l0 319.8-5-6.5-136-176c-4.5-5.9-11.6-9.3-19-9.3s-14.4 3.4-19 9.3L202 340.7l-30.5-42.7C167 291.7 159.8 288 152 288s-15 3.7-19.5 10.1l-80 112L48 416.3l0-.3L48 96c0-8.8 7.2-16 16-16l384 0zM64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zm80 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"
                    />
                  </svg>
                  <p style="padding-left: 5px">{{ totalPhotos }}</p>
                </div>
                <div style="display: flex; align-items: center">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="15" height="15">
                    <path
                      d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l224 0 0-112c0-26.5 21.5-48 48-48l112 0 0-224c0-35.3-28.7-64-64-64L64 32zM448 352l-45.3 0L336 352c-8.8 0-16 7.2-16 16l0 66.7 0 45.3 32-32 64-64 32-32z"
                    />
                  </svg>
                  <p style="padding-left: 5px">{{ activePunchList?.tasks?.length }}</p>
                </div>
              </div>
              <div style="display: flex; gap: 8px">
                <Button
                  :style="{
                    width: '30px',
                    height: '30px',
                    backgroundColor: 'black',
                    borderColor: 'black',
                    padding: '10px',
                  }"
                  @click="createTask(punchList)"
                >
                  <template v-if="isSubmittingTask">
                    <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" class="loaderButtonPunchlist">
                      <circle cx="25" cy="25" r="20" stroke="white" stroke-width="4" fill="none" stroke-dasharray="31.4" stroke-dashoffset="0" />
                    </svg>
                  </template>
                  <template v-else>
                    <svg viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet">
                      <path
                        fill="white"
                        d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32v144H48c-17.7 0-32 14.3-32 32s14.3 32 32 32h144v144c0 17.7 14.3 32 32 32s32-14.3 32-32V288h144c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                      />
                    </svg>
                  </template>
                </Button>
                <Button
                  :style="{
                    width: '30px',
                    height: '30px',
                    backgroundColor: 'black',
                    borderColor: 'black',
                    padding: '10px',
                  }"
                  @click="handleDelete(punchList)"
                >
                  <template v-if="isSubmittingPunchlist">
                    <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" class="loaderButtonPunchlist">
                      <circle cx="25" cy="25" r="20" stroke="white" stroke-width="4" fill="none" stroke-dasharray="31.4" stroke-dashoffset="0" />
                    </svg>
                  </template>
                  <template v-else>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="50" height="50">
                      <path
                        fill="white"
                        d="M135.2 17.7L128 32 32 32C14.3 32 0 46.3 0 64S14.3 96 32 96l384 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-96 0-7.2-14.3C307.4 6.8 296.3 0 284.2 0L163.8 0c-12.1 0-23.2 6.8-28.6 17.7zM416 128L32 128 53.2 467c1.6 25.3 22.6 45 47.9 45l245.8 0c25.3 0 46.3-19.7 47.9-45L416 128z"
                      />
                    </svg>
                  </template>
                </Button>
              </div>
            </div>
            <ul role="list" class="close-out-list w-full">
              <ListItemComponent
                v-for="task in activePunchList.tasks"
                :key="task.id"
                :type="'LIST'"
                :item="task"
                :active-id="''"
                :check-state="task.status === 'COMPLETED' ? true : task.status === 'NA' ? false : null"
                @on-edit-task="handleEditTask(task)"
                @refresh="refreshAll"
              />
            </ul>
            <div v-if="!activePunchList?.tasks || activePunchList.tasks.length === 0" style="display: flex; flex: 1; justify-content: center; margin-top: -5px">
              <p style="color: gray; font-size: 10px">{{ t('projectPunchList.notTask') }}</p>
            </div>
          </div>
        </div>
        <div v-else class="col col-12">Loading...</div>
      </AccordionTab>
    </Accordion>
  </div>

  <div v-else-if="loadingProjectPunchList" class="col col-12">Loading...</div>

  <div v-else class="col-12">
    <div class="flex align-items-center justify-content-center flex-wrap col gap-2 h-20rem">
      <h6>{{ $t('projectPunchList.noPunchlistsYet') }}</h6>
    </div>
  </div>

  <!-- Add Punch List -->
  <Dialog
    v-model:visible="punchListDialogIsActive"
    :position="!md ? 'bottom' : undefined"
    class="w-full m-0"
    style="max-width: 42rem"
    :modal="true"
    :draggable="false"
    :header="t('projectPunchList.editPunchList')"
    @update:visible="onChangePunchlist"
  >
    <ProjectPunchListAdd :payload="payloadPuchlist" :is-editing="true" :project-id="props.projectId" @close-add-dialog="closeAddDialog" />
    <div
      :style="{
        marginBottom: !md ? 'calc(var(--sab) + 4rem) !important' : '',
      }"
    ></div>
  </Dialog>

  <!-- Add Tasks -->
  <Dialog
    v-model:visible="tasksDialogIsActive"
    :position="!md ? 'bottom' : undefined"
    class="w-full m-0 hide-on-camera"
    style="max-width: 42rem"
    :modal="true"
    :draggable="false"
    :header="isEditTask ? t('projectPunchList.editTask') : t('projectPunchList.newTask')"
    @update:visible="onChangeTask"
  >
    <TasksAdd :is-editing="isEditTask" :payload="payloadTask" :project-id="props.projectId" :task-list-id="activeTaskListId || ''" @close-add-tasks-dialog="closeAddTasksDialog" />
    <div
      :style="{
        marginBottom: !md ? 'calc(var(--sab) + 4rem) !important' : '',
      }"
    ></div>
  </Dialog>
  <Dialog
    v-model:visible="showDialogDeletePunchlist"
    :position="!md ? 'bottom' : undefined"
    class="w-full m-0 hide-on-camera"
    style="max-width: 42rem"
    :modal="true"
    :draggable="false"
    :header="dialogModal.title"
  >
    <p>{{ dialogModal.description }} - {{ dialogModal.entity }}</p>
    <div>
      <Button
        :label="t('projectPunchList.labelModalCancelButton')"
        severity="primary"
        :outlined="true"
        class="border-0 shadow-none"
        raised
        @click="() => (showDialogDeletePunchlist = false)"
      />
      <Button
        :label="t('projectPunchList.labelModalDeleteButton')"
        severity="danger"
        :disabled="loadingDeleting"
        :loading="loadingDeleting"
        raised
        @click="handleAPIDeleteGeneral"
      />
    </div>
  </Dialog>
</template>

<style>
  .loaderButtonPunchlist {
    animation: spin 1s linear infinite;
    width: 24px;
    height: 24px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>

<style lang="scss" scoped>
  .title-tab {
    flex-direction: column;
    min-height: 40px;
  }

  .close-out-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    list-style: none;
    margin: 0;
    padding: 0;
    border-radius: 8px;
  }
  .list {
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);

      &:first-child {
        border-top: 1px solid rgba(0, 0, 0, 0.3);
      }
    }
  }
</style>

<style lang="scss">
  #photo-comment .p-sidebar-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  #photo-comment .p-sidebar-header-content {
    width: 100%;
  }
  #photo-comment .p-sidebar-content {
    height: calc(100% - 63px);
    max-height: calc(100% - 63px);
    padding: 0;
    overflow: hidden;
  }
</style>
