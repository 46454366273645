<script setup lang="ts">
  import { getPermission, getPermissionsUser } from '@/components/PermissionRole/funtions'
  import PermissionRole from '@/components/PermissionRole/PermissionComponent.vue'
  import { IQueuePhoto, PhotoTypeEnum } from '@/db/tables'
  import { i18n } from '@/i18n'
  import { ref } from 'vue'
  import api from '@/services/api'
  import { useToast } from 'primevue/usetoast'
  import { captureException } from '@sentry/vue'
  import ProjectProblemCardDescription from '@/components/ProjectProblemCard/ProjectProblemCardDescription.vue'
  import { ICamera } from '@/components/PunchListCamera/Camera/CameraContainer.vue'
  import { useEventBus } from '@vueuse/core'
  import { useBreakpointsStore, useProjectStore } from '@/store'
  import { ProblemStatusEnum } from '@/interfaces/problem'
  import { storeToRefs } from 'pinia'
  import GalleryComponent from '@/components/GalleryComponent.vue'
  import CardProblemPhoto from './CardProblemPhoto.vue'
  import { uploadPhotosBlob } from '@/utils/uploadPhotoBlob'
  import { base64ToBlob } from '@/utils/base64Toblob'
  import ProjectMediaVideoComponent from '@/components/ProjectMedia/ProjectMediaVideoComponent.vue'

  interface Props {
    problem: any
    project: any
  }

  const camera = useEventBus<ICamera>('camera')
  const toast = useToast()
  const deleteLoading = ref(false)
  const displayConfirmation = ref(false)
  const breakpointsStore = useBreakpointsStore()
  const { md } = storeToRefs(breakpointsStore)
  const projectStore = useProjectStore()
  const { project } = storeToRefs(projectStore)
  const props = defineProps<Props>()

  const home = ref({ icon: 'pi pi-wrench', label: i18n.global.t('projectProblems.problems') })
  const items = ref([{ label: truncateString(props.problem.description, 15) }])

  function updateMenuItems() {
    menuItems.value = filteredMenuItems(allMenuItems, props.problem.problemStatus)
  }

  const emit = defineEmits(['updateProblem', 'removeProblem', 'hideProblem'])

  async function markInProgress() {
    try {
      const payload = { problemStatus: 'IN_PROGRESS' }

      const {
        data: { data: resp },
      } = await api.patch(`/Api/Problem/${props.problem.id}`, payload)

      emit('updateProblem', resp)

      updateMenuItems()
      toast.add({ severity: 'success', summary: t('appLayout.titlesuccess'), detail: i18n.global.t('projectProblem.successMarkedInProgress'), life: 3000 })
    } catch (err) {
      captureException(err)
      console.log('err', err)
      toast.add({ severity: 'error', summary: t('appLayout.titleunsuccess'), detail: i18n.global.t('projectProblem.errorMarkedInProgress'), life: 3000 })
    }
  }

  async function markOpen() {
    try {
      const payload = { problemStatus: 'OPEN' }

      const {
        data: { data: resp },
      } = await api.patch(`/Api/Problem/${props.problem.id}`, payload)

      emit('updateProblem', resp)

      updateMenuItems()
      toast.add({ severity: 'success', summary: t('appLayout.titlesuccess'), detail: i18n.global.t('projectProblem.successMarkedOpen'), life: 3000 })
    } catch (err) {
      captureException(err)
      console.log('err', err)
      toast.add({ severity: 'error', summary: t('appLayout.titleunsuccess'), detail: i18n.global.t('projectProblem.errorMarkedOpen'), life: 3000 })
    }
  }

  function truncateString(str: string, num: number) {
    if (str.length <= num) return str
    return str.slice(0, num) + '...'
  }

  function startClosedProcess() {
    camera.emit({ propsCamera: { projectId: project.value.id, photoType: PhotoTypeEnum.PROBLEM }, actions: { closeCamera: closeCamera } })
  }

  async function markClosed(photosid: string[]) {
    try {
      const payload = { problemStatus: 'CLOSED', photoAfterIds: photosid }

      const {
        data: { data: resp },
      } = await api.patch(`/Api/Problem/${props.problem.id}`, payload)

      emit('updateProblem', resp)

      toast.add({ severity: 'success', summary: t('appLayout.titlesuccess'), detail: i18n.global.t('projectProblem.successMarkedClosed'), life: 3000 })
    } catch (err) {
      captureException(err)
      console.log('err', err)
      toast.add({ severity: 'error', summary: t('appLayout.titleunsuccess'), detail: i18n.global.t('projectProblem.errorMarkedClosed'), life: 3000 })
    }
  }

  function toggleConfirmation() {
    displayConfirmation.value = !displayConfirmation.value
  }

  const allMenuItems = [
    { label: i18n.global.t('projectProblem.open'), value: 'OPEN', command: markOpen, nodoname: 'projectsProblemsPermissions', keyname: 'closed' },
    { label: i18n.global.t('projectProblem.inProgress'), value: 'IN_PROGRESS', command: markInProgress, nodoname: 'projectsProblemsPermissions', keyname: 'closed' },
    { label: i18n.global.t('projectProblem.closed'), value: 'CLOSED', command: startClosedProcess, nodoname: 'projectsProblemsPermissions', keyname: 'closed' },
    {
      label: i18n.global.t('projectProblem.delete'),
      value: 'DELETE',
      command: toggleConfirmation,
      nodoname: 'projectsProblemsPermissions',
      keyname: 'delete',
      class: 'text-red',
      style: {
        color: 'red',
      },
    },
  ]

  interface IMenuItem {
    label?: string
    value?: string
    command?: () => void | Promise<void>
    nodoname: string
    keyname: string
    class?: string
    style?: any
  }

  const filteredMenuItems = (allItems: IMenuItem[], problemStatus: ProblemStatusEnum) => {
    return allItems.filter((item) => item.value !== problemStatus).filter((item) => getPermission(getPermissionsUser(), item?.keyname, item?.nodoname))
  }

  const menu = ref()
  const { t } = i18n.global
  const menuItems = ref(filteredMenuItems(allMenuItems, props.problem.problemStatus))

  const closeCamera = async (photos: IQueuePhoto[]) => {
    if (photos.length === 0) return
    const photoUploadSuccess = await uploadPhotosBlob(
      photos.map((photo) => {
        const blob = base64ToBlob(`${photo.base64}`, 'image/jpeg')
        return { ...photo, file: blob }
      })
    )
    await markClosed(photoUploadSuccess.map((photo) => photo.id))
  }

  function toggleMenu(event: MouseEvent) {
    if (menu.value && menu.value.toggle) {
      menu.value.toggle(event)
    } else if (menu.value && menu.value[0] && menu.value[0].toggle) {
      menu.value[0].toggle(event)
    }
    event.preventDefault()
    event.stopPropagation()
  }

  async function confirmDeletion() {
    try {
      deleteLoading.value = true
      await api.delete(`/Api/Problem/${props.problem.id}`)
      emit('removeProblem', props.problem.id)
      toast.add({ severity: 'success', summary: t('appLayout.titlesuccess'), detail: i18n.global.t('projectProblem.successDeleted'), life: 3000 })
      deleteLoading.value = false
      toggleConfirmation()
    } catch (err) {
      captureException(err)
      console.log('err', err)
      toast.add({ severity: 'error', summary: t('appLayout.titleunsuccess'), detail: i18n.global.t('projectProblem.errorDeleting'), life: 3000 })
    }
  }
</script>
<template>
  <div class="flex p-2 justify-content-between">
    <div>
      <Breadcrumb :home="home" :model="items">
        <template #item="{ item }">
          <template v-if="item.icon === 'pi pi-wrench'">
            <span class="cursor-pointer flex items-center justify-content-center" @click="emit('hideProblem')">
              <i style="font-weight: bold" :class="item.icon + ' mr-2'" />
              <span class="font-semibold">{{ item.label }}</span>
            </span>
          </template>
          <template v-else>
            <i :class="item.icon + ' mr-2'" />
            <span>{{ item.label }}</span>
          </template>
        </template>
      </Breadcrumb>
    </div>
    <PermissionRole v-if="project.isAuthorized && !project.isClosed" nodo-name="projectsProblemsPermissions" key-name="options">
      <div class="flex justify-content-end">
        <Button text icon="pi pi-ellipsis-v" @click="toggleMenu" />
        <Menu ref="menu" :model="menuItems" :popup="true" />
      </div>
    </PermissionRole>
  </div>
  <div class="px-2 pb-6">
    <Card>
      <template #content>
        <div>
          <ProjectProblemCardDescription :problem="problem" :description-type="'long'" />
        </div>
      </template>
    </Card>
    <Dialog
      v-model:visible="displayConfirmation"
      :position="!md ? 'bottom' : undefined"
      :header="i18n.global.t('projectProblem.confirmDeletion')"
      class="w-full m-0"
      style="max-width: 36rem"
      :modal="true"
      :dismissable-mask="true"
      :draggable="false"
    >
      <div class="confirmation-content">
        <span>{{ $t('projectProblem.areYouSure') }}</span>
      </div>
      <template #footer>
        <Button :label="i18n.global.t('projectProblem.cancel')" class="p-button-outlined" @click="toggleConfirmation" />
        <Button :label="i18n.global.t('projectProblem.confirm')" class="p-button-danger" @click="confirmDeletion" />
      </template>
    </Dialog>
  </div>

  <GalleryComponent
    :show-comments="false"
    show-detail
    entity-type="Projects"
    :images="
      problem.projectPhotos.map((photo: any) => ({
        url: photo.url,
        id: photo.id,
        ...photo,
      }))
    "
  >
    <template #item="slotProps">
      <CardProblemPhoto v-if="slotProps.item?.mediaType === 'IMAGE'" :photo="slotProps.item" />
      <ProjectMediaVideoComponent v-if="slotProps.item?.mediaType === 'VIDEO'" :is-upload-type-available="false" :photo="slotProps.item" :is-tag-available="false" />
    </template>
  </GalleryComponent>
</template>
