<script lang="ts" async setup>
  import { i18n } from '@/i18n'
  import ChangePassword from '@/components/account/ChangePassword.vue'
  import { useRouter } from 'vue-router'
  import { useToast } from 'primevue/usetoast'

  const router = useRouter()
  const toast = useToast()

  const { t } = i18n.global
  const submit = async () => {
    await router.push('/')
  }

  const errorSubmit = async (message: string) => {
    toast.add({ severity: 'error', summary: t('appLayout.titleunsuccess'), detail: message, life: 3000 })
  }
</script>

<template>
  <div class="grid m-0 p-0" style="background-image: url('/assets/img/bg/bg1.webp')">
    <div class="col h-screen m-0 p-0">
      <div class="flex align-items-center justify-content-center h-screen">
        <ChangePassword @submit-success="submit" @submit-error="errorSubmit" />
      </div>
    </div>
  </div>
  <Toast position="bottom-right" />
</template>

<style lang="scss" scoped>
  .grid {
    background-position: center;
    background-size: cover;
    background-repeat: repeat;
  }
</style>
